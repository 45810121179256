import twodlogoanimation from 'assets/videos/clubhouse/2d-logo-animation.mp4';
import twodthreed from 'assets/videos/clubhouse/2d-to-3d.mp4';
import threediconography from 'assets/videos/clubhouse/3d-iconography.mp4';
import fourlines from 'assets/videos/clubhouse/4-line-icons.mp4';
import advertising from 'assets/videos/clubhouse/advertising.mp4';
import allgreenlogo from 'assets/videos/clubhouse/all-green-logo.mp4';
import biglogo from 'assets/videos/clubhouse/big-logo.mp4';
import billbadder from 'assets/videos/clubhouse/bill-badder.mp4';
import coloranimation from 'assets/videos/clubhouse/color-animation.mp4';
import customappeals from 'assets/videos/clubhouse/custom-appeals.mp4';
import darkonelinelogo from 'assets/videos/clubhouse/dark-one-line-logo.mp4';
import digitalprint from 'assets/videos/clubhouse/digital-print.mp4';
import expoui from 'assets/videos/clubhouse/expo-ui.mp4';
import fontanimation from 'assets/videos/clubhouse/font-animation.mp4';
import girlemoji from 'assets/videos/clubhouse/girl-emoji.mp4';
import groupicon from 'assets/videos/clubhouse/group-icon.mp4';
import keywords from 'assets/videos/clubhouse/keywords.mp4';
import logodraw from 'assets/videos/clubhouse/logo-draw-color.mp4';
import longlogoani from 'assets/videos/clubhouse/long-logo-ani.mp4';
import modelpattern from 'assets/videos/clubhouse/model-pattern.mp4';
import nycnew from 'assets/videos/clubhouse/nyc-new.mp4';
import patternanimation from 'assets/videos/clubhouse/pattern-animation.mp4';
import patternstyle from 'assets/videos/clubhouse/pattern-style.mp4';
import peoplewave from 'assets/videos/clubhouse/people-wave.mp4';
import renderhere from 'assets/videos/clubhouse/render-here.mp4';
import squarepattern from 'assets/videos/clubhouse/square-pattern.mp4';
import talkless from 'assets/videos/clubhouse/talk-less.mp4';
import visuallanguage from 'assets/videos/clubhouse/visual-language.mp4';
import waveanimation from 'assets/videos/clubhouse/wave-animation.mp4';
import wireframe from 'assets/videos/clubhouse/wireframe.mp4';

// 3d icons
import art from 'assets/videos/clubhouse/3d-icons/art.mp4';
import entertainment from 'assets/videos/clubhouse/3d-icons/entertainment.mp4';
import hangingout from 'assets/videos/clubhouse/3d-icons/hanging-out.mp4';
import hustle from 'assets/videos/clubhouse/3d-icons/hustle.mp4';
import knowledge from 'assets/videos/clubhouse/3d-icons/knowledge.mp4';
import life from 'assets/videos/clubhouse/3d-icons/life.mp4';
import places from 'assets/videos/clubhouse/3d-icons/places.mp4';
import sports from 'assets/videos/clubhouse/3d-icons/sports.mp4';

export const CLUBHOUSE_VIDEOS = {
  twodlogoanimation,
  twodthreed,
  threediconography,
  fourlines,
  advertising,
  allgreenlogo,
  biglogo,
  billbadder,
  coloranimation,
  darkonelinelogo,
  customappeals,
  digitalprint,
  expoui,
  fontanimation,
  girlemoji,
  groupicon,
  keywords,
  logodraw,
  longlogoani,
  modelpattern,
  nycnew,
  patternanimation,
  patternstyle,
  peoplewave,
  renderhere,
  squarepattern,
  talkless,
  visuallanguage,
  waveanimation,
  wireframe,

  art,
  entertainment,
  hangingout,
  hustle,
  knowledge,
  life,
  places,
  sports
};
