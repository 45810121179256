import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'components';

export const Header = ({ navHidden, isTab }) => (
  <header className="header">
    <Navigation navHidden={navHidden} isTab={isTab} />
  </header>
);

Header.propTypes = {
  isTab: PropTypes.bool,
  navHidden: PropTypes.bool
};
