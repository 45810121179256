import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';

export const CrackleBase = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              MTV Crackle and Base is a music morning show that compiles and
              shares all the best morning songs to get you ready for the day. so
              we decided to take a more creative approach to help sell the idea.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Final Video</h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://vimeo.com/280441385"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Style Frames</h3>

            <p className="paragraph text-center">
              From content strategy to story boarding
            </p>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.cracklebase.cracklestyle1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.cracklebase.cracklestyle2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.cracklebase.cracklestyle3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.cracklebase.cracklestyle4}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.cracklebase.cracklestyle5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.cracklebase.cracklestyle6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

CrackleBase.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
