import React from 'react';
import logo from 'assets/img/logo.gif';

export const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-img">
        <img src={logo} alt="spencerzill" className="img-fluid" />
      </div>
    </div>
  );
};
