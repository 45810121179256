export const projectModel = {
  name: '',
  header: '',
  footer: {},
  list: {},
  credits: []
};

export const YELLOWSTAR_BASE_URL =
  'https://res.cloudinary.com/dky2q372t/video/upload/v1620077230/spencerzill/yellowstar/';

export const GEELY_BASE_URL =
  'https://res.cloudinary.com/dky2q372t/video/upload/v1620077227/spencerzill/geely/';

export const CLUBHOUSE_BASE_URL =
  'https://res.cloudinary.com/dky2q372t/video/upload/';
