import band from 'assets/videos/ontt/band.mp4';
import comp1 from 'assets/videos/ontt/comp1.mp4';
import comp2 from 'assets/videos/ontt/comp2.mp4';
import front from 'assets/videos/ontt/front.mp4';
import mtv from 'assets/videos/ontt/mtv.mp4';
import plain from 'assets/videos/ontt/plain.mp4';
import table from 'assets/videos/ontt/table.mp4';
import trim1 from 'assets/videos/ontt/trim1.mp4';
import trim2 from 'assets/videos/ontt/trim2.mp4';

export const ONTT_VIDEOS = {
  band,
  comp1,
  comp2,
  front,
  mtv,
  plain,
  table,
  trim1,
  trim2
};
