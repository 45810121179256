// central cast project
import cast1 from 'assets/img/projects/central-cast/cast1.png';
import cast2 from 'assets/img/projects/central-cast/cast2.png';
import cast3 from 'assets/img/projects/central-cast/cast3.png';
import cast4 from 'assets/img/projects/central-cast/cast4.png';
import castheader from 'assets/img/projects/central-cast/header.png';
import castidea1 from 'assets/img/projects/central-cast/idea1.png';
import castidea2 from 'assets/img/projects/central-cast/idea2.png';
import castidea3 from 'assets/img/projects/central-cast/idea3.png';
import castidea4 from 'assets/img/projects/central-cast/idea4.png';
import castidea5 from 'assets/img/projects/central-cast/idea5.png';
import castidea6 from 'assets/img/projects/central-cast/idea6.png';
import castmood1 from 'assets/img/projects/central-cast/mood1.png';
import castmood2 from 'assets/img/projects/central-cast/mood2.png';
import castmood3 from 'assets/img/projects/central-cast/mood3.png';
import caststyle1 from 'assets/img/projects/central-cast/style1.png';
import caststyle2 from 'assets/img/projects/central-cast/style2.png';
import caststyle3 from 'assets/img/projects/central-cast/style3.png';
import caststyle4 from 'assets/img/projects/central-cast/style4.png';
import caststyle5 from 'assets/img/projects/central-cast/style5.png';
import caststyle6 from 'assets/img/projects/central-cast/style6.png';
import caststyle7 from 'assets/img/projects/central-cast/style7.png';
import caststyle8 from 'assets/img/projects/central-cast/style8.png';

const CENTRAL_CAST_IMAGES = {
  cast1,
  cast2,
  cast3,
  cast4,
  castheader,
  castidea1,
  castidea2,
  castidea3,
  castidea4,
  castidea5,
  castidea6,
  castmood1,
  castmood2,
  castmood3,
  caststyle1,
  caststyle2,
  caststyle3,
  caststyle4,
  caststyle5,
  caststyle6,
  caststyle7,
  caststyle8
};

export default CENTRAL_CAST_IMAGES;
