export const formatCharLength = (str, len) =>
  str.length > len ? `${str.substring(0, len - 1)}...` : str;

export const isArrayEmpty = (arr) => !arr || arr.length === 0;

export const isObjectEmpty = (obj) => {
  // eslint-disable-next-line no-unused-vars
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
};
