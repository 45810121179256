import React from 'react';
// import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import { ProjectContainer } from 'layouts/ProjectContainer';
// import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
// import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';
// import GridSections from 'components/projects/clubhouse-sections/GridSections';
import { MetaDecorator } from 'components/MetaDecorator';
import { CustomVideo } from 'components/CustomVideo';

import useWindowDimensions from '../../hooks/getDeviceWidth';

// export const Raven = ({ isMobile, projectInfo }) => {
export const Raven = ({ projectInfo }) => {
  const { height, width } = useWindowDimensions();
  return (
    <>
      <MetaDecorator image="clubhouse.jpg" title="Raven Bank" />

      <ProjectContainer
        projectInfo={projectInfo}
        className="raven"
      ></ProjectContainer>
      <div className="container-fluid p-0 mw-1440">
        <div className="row no-gutters">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/709021545?h=d5f68abad0"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/14a1bd144665791.6295544acdd45.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/04b91e144665791.6295544acd181.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/b7ff36144665791.629a0c8d4d0ee.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <iframe
            src="https://player.vimeo.com/video/716300920?h=20a844750a&amp;autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allow="autoplay; picture-in-picture"
            allowFullScreen
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/0cf4aa144665791.62963bc0ac82a.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/f2b2c1144665791.6290d407757ba.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/83f6ce144665791.6296474e28fe1.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-12">
            <CustomVideo
              src="https://res.cloudinary.com/https-spencerzill-com/video/upload/v1650432592/RavenBank/videos/RavenVideo_wu1hqa.mp4"
              type="clubhouse"
              width="w-full"
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/d5e642144665791.62963bc0ac0a9.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/83754e144665791.62989f33845ef.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/c929a5144665791.62989f3384d7d.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/458a7c144665791.6291ec22611d2.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/f434d7144665791.62904ab411fd5.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/48a34b144665791.629643230d1b6.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/b72e2d144665791.6291ec225f605.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-6 col-sm-12 push-top">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/a6d025144665791.62925dbf2159a.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12 push-top">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/dfe312144665791.62925dbf21ee9.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/3cf05b144665791.6292629b83e8d.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/a84ea1144665791.6291ec225edcc.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/9a56b0144665791.6291ec2265251.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          {/* COLORS AND GRADIENT SECTION */}
          <div className="col-md-7 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/b6a403144665791.6291ec219e1a6.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-5 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/c20c2b144665791.6291ec219e8f9.gif"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          {/* ANOTHER VIDEO GOES HERE */}
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/59c75d144665791.629651f57a524.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/2186f2144665791.6291ec2261e08.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/1135ee144665791.6291ec226480a.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/b44039144665791.6291ec226357d.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/dcf816144665791.629645ef51861.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/81f598144665791.6291ec2264074.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/33ce42144665791.62963bc0ab43e.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/760c0b144665791.62924c00bfc24.gif"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/d50cc0144665791.62924c00c0408.gif"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/870435144665791.629254f56bffd.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/eb9814144665791.6292625196edb.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/67d4b0144665791.629254f56b82c.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/ea2ae7144665791.6291ec2266ab7.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/ff708f144665791.6291ec22604e7.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/2703fc144665791.6291ec2262887.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/cd94ae144665791.6291ec225e1d1.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/2e3338144665791.62920a57c12f5.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/d4c20f144665791.62921051d83eb.jpeg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/298504144665791.62921110dcad3.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/189861144665791.62921110dd12d.jpeg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/a508f1144665791.62921110dc5f4.jpeg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/89c831144665791.629213ca17970.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/9460bb144665791.629364505ef3d.jpeg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/d98c3c144665791.6293cb7e64d9b.jpeg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/eacfd2144665791.629364505e9ba.jpeg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/f4ce6e144665791.6293d1318bb45.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/48a4e3144665791.62936450c5003.jpeg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/cdbb3a144665791.62936450c46d8.jpeg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c4458b144665791.62936450c5832.jpeg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/879a04144665791.62923fc6ab65f.jpeg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/780225144665791.62923fc4406c8.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/3577b3144665791.62923fc58251c.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/dbe02b144665791.62923fc582de2.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <iframe
            src="https://player.vimeo.com/video/716326048?h=abe5a3b888&amp;autoplay=1&amp;loop=10"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allow="autoplay; picture-in-picture"
            allowFullScreen
            className="push-top"
          ></iframe>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/96e842144665791.6291b5468e7fe.gif"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/3b4469144665791.6291b5468d1d5.png"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/c505a6144665791.6291b5468da75.png"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/fb1bf7144665791.6291b5468c779.png"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/9ad730144665791.6291b5468e073.png"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/6cfdd1144665791.62964322a03f5.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/a78a3c144665791.62964322a0b35.jpg"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/79c10d144665791.62925dbf7d560.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/4625ad144665791.629643230da45.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/c4e704144665791.629643230cb66.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          {/* <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/714761688?h=f1977c71e0"
          width={width}
          height={width < 1000 ? width / 2 : height}
          frameBorder="0"
          allowFullScreen
        ></iframe> */}
          <iframe
            src="https://player.vimeo.com/video/714761688?h=f1977c71e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allow="autoplay; picture-in-picture"
            allowFullScreen
            className="push-top"
            title="Raven Brand Launch"
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/161945144665791.62924c011add7.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <iframe
            src="https://player.vimeo.com/video/714767400?h=2667c4ce7c&autoplay=1&loop=1&muted=1"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="RAVEN BANK"
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/c6853b144665791.629643c6a9930.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/315341144665791.629268fe97741.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/49b573144665791.629651f4cb9ec.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/61116e144665791.629643c6aaaf9.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/6941d4144665791.62929fa0dd09b.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/8b2ca1144665791.62929fa0dcb00.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/ffce6d144665791.62929fa0dc4c3.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/da1a66144665791.62929fa0dbe92.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/f44b94144665791.62935ee964501.gif"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <CustomImage
              hasScrollEffect
              src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/2ad927144665791.62935ee964b13.gif"
              className="project-image"
              height="100%"
              alt=""
            />
          </div>
          <iframe
            src="https://player.vimeo.com/video/716389258?h=48e3e36c0d&amp;color=9be5ca&amp;byline=0&amp;portrait=0"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allow="autoplay; picture-in-picture"
            allowFullScreen
            title="Rebranding Raven_Client Feedback_"
            className="push-top"
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/2e528c144665791.62955910e983d.jpg"
            className="project-image push-top"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/175bb6144665791.629358424f81a.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/27b184144665791.62904ab107554.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/2818ca144665791.629643c6aa201.gif"
            className="project-image"
            height="100%"
            alt=""
          />
          <iframe
            src="https://player.vimeo.com/video/716357376?h=100367ba38&byline=0&portrait=0"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allow="autoplay; picture-in-picture"
            allowFullScreen
            title="Rebranding Raven_Client Feedback_"
            className="push-top"
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/5d0ee3144665791.62935ee9bfc7f.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/6e398c144665791.6296749d092e3.jpg"
            className="project-image"
            height="100%"
            alt=""
          />

          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/716335196?h=9ad9a60f44&title=0&byline=0&portrait=0"
            width={width}
            height={width < 1000 ? width / 2 : height - 5}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <CustomImage
            hasScrollEffect
            src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/30d628144665791.62935ee9bf33b.jpg"
            className="project-image"
            height="100%"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

Raven.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
