import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PageContainer } from 'layouts/PageContainer';
import { motion } from 'framer-motion';
import { ANIMATION_DEFAULTS, SOCIAL_IMAGES } from 'utility/constants';
import { CustomImage, HeroSection, MetaDecorator } from 'components';
import { ABOUT_IMAGES as IMAGES } from 'utility/images';
import Bg from 'assets/img/section-bg.png';

const About = ({ isTab }) => {
  const [showMore, setShowMore] = useState();
  const toggleShowMore = () => setShowMore((prevState) => !prevState);

  return (
    <PageContainer pageClass="page-body about" isTab={isTab}>
      <MetaDecorator
        description="We increase profits. Others play with pixels"
        title="About - SpencerZill"
      />

      <motion.div
        exit="out"
        initial="out"
        animate="in"
        variants={ANIMATION_DEFAULTS.pageTransition}
        transition={ANIMATION_DEFAULTS.duration}
      >
        <HeroSection title="About" />

        <section
          className={classnames('section about-heading', { 'mb-5': !isTab })}
        >
          <div className="container container-mobile">
            <h3 className="heading heading--xl heading--xl-nm mb-5">
              We increase profits. <span>Others play with pixels.</span>
            </h3>

            <div className="extra-left-padding">
              <p className="paragraph">
                We are SpencerZill, a team of creative professionals aimed at
                designing world class visuals to tell stories with an African
                edge. Part of our job is to find surprising and playful ways to
                tell a story, by using data for informed design decisions,
                implementing design solutions for brand/product growth, and
                applying design principles for increased engagement.
              </p>
            </div>
          </div>
        </section>

        <section className="section skills">
          <div className="container container-mobile skills-container">
            <div className="skills-row">
              <div className="skills-col--big mb-5">
                <h3 className="skills__heading">Skills</h3>
              </div>

              <div className={classnames('row', { 'no-gutters': isTab })}>
                <div className="col-md-4 mb-5 skills-col">
                  <p className="paragraph skills__title">
                    Story &amp; <span>Design</span>
                  </p>
                  <p className="paragraph">Full branding</p>
                  <p className="paragraph">Arts &amp; creative direction</p>
                  <p className="paragraph">Products design &amp; packaging</p>
                  <p className="paragraph">High end rendering</p>
                  <p className="paragraph">Research &amp; look development</p>
                  <p className="paragraph">Problem driven design solutions</p>
                </div>

                <div className="col-md-4 mb-5 skills-col">
                  <p className="paragraph skills__title">
                    CGI &amp; <span> Film </span>
                  </p>
                  <p className="paragraph">3D &amp; 2D illustration</p>
                  <p className="paragraph">Story boards &amp; treatments</p>
                  <p className="paragraph">Documentary</p>
                  <p className="paragraph">Advert promos &amp; trailers</p>
                  <p className="paragraph">Short film</p>
                  <p className="paragraph">Live-action production</p>
                  <p className="paragraph">Post production &amp; VFX</p>
                </div>

                <div className="col-md-4 mb-5 skills-col">
                  <p className="paragraph skills__title">
                    Motion Design &amp; <span>Animation</span>
                  </p>

                  <p className="paragraph">3D &amp; 2D animation</p>
                  <p className="paragraph">CGI stop motion</p>
                  <p className="paragraph">Technical managment</p>
                  <p className="paragraph">TV branding</p>
                  <p className="paragraph">Motion broadcast design</p>
                  <p className="paragraph">Explainer videos title design</p>
                  <p className="paragraph">Logo idents</p>
                  <p className="paragraph">Short animation film</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section--lg who-we-are">
          <div className="container container-mobile">
            <h3 className="heading heading--xl heading--xl-nm  mb-5">
              Who we are
            </h3>

            <div className="extra-left-padding mb-5">
              <p className="paragraph">
                We are Creative Problem Solvers with an excellent eye for
                details, focused on bringing novelty into the world of design
                &amp; production, on a mission to make what is digital more
                human, challenge African stories towards global aesthetic while
                leveraging on cultures and lifestyles from around the world, we
                design for the future. This idea remains our highest aspiration;
                The guiding force behind each research conducted, pixel moved,
                and line of code written.
              </p>
            </div>

            <div className="text-center pt-5 pb-5">
              <img src={IMAGES.whoweare} className="img-fluid" alt="" />
            </div>
          </div>
        </section>

        <section className="section section--lg pb-0">
          <div className="container container-mobile">
            <h3 className="heading heading--xl heading--xl-nm mb-5">
              Our Story
            </h3>

            <div className="extra-left-padding">
              <p className="paragraph">
                What started as a side-gig,through the years turned into a fully
                remote digital company.{' '}
                {(!isTab || showMore) && (
                  <span>
                    Being fearless defines who we are, how we think and how we
                    work. Being fearless means being passionate about making a
                    difference, fearlessly seeking the future and not just
                    waiting for it to arrive, it’s one of the things that’s
                    driven us to think beyond. We’ve been privileged to work
                    with startups, SME&apos;s, corporations, and fortune 500
                    companies all over the world, with a specialty in motion
                    design, CG, branding &amp; visual effects.
                  </span>
                )}
              </p>

              {(!isTab || showMore) && (
                <>
                  <p className="paragraph color-off-white pt-4">
                    Outside of our client work, we enjoy giving back to the
                    community through Acts of Random Kindness projects, and
                    helping creators showcase their work, celebrate and inspire
                    the future Motion design generation with Gomotion, a motion
                    design community to empower African motion designers.
                  </p>
                </>
              )}

              {isTab && (
                <div className="readmore" onClick={toggleShowMore}>
                  <p>{showMore ? 'Read less' : 'Read more'}</p>
                  <div className="readmore-icon">
                    <span
                      className={classnames('icon-long-arrow', {
                        'rotate-span': showMore
                      })}
                    ></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container container-mobile">
            <div className="text-center pt-5 pb-5">
              <img src={IMAGES.about7} className="img-fluid w-100" alt="" />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container container-mobile">
            <div className="page-grid">
              <div className="grid-content grid-content--br grid-content--bb">
                <h3 className="heading heading--xl heading--xl-nm  color-off-white">
                  Our Values
                </h3>

                <div className="min-grid-content">
                  <p className="paragraph">
                    Relationships are our gold standard. We want to work with
                    awesome people, because for us, success is measured by
                    positive experiences, not the bottom line. As such, we
                    strive to surround ourselves with both smart and passionate
                    people, those who will grow our minds, hearts and not just
                    our wallets.
                  </p>

                  {isTab && (
                    <p className="paragraph color-off-white">
                      We approach every problem with an open mind, and sometimes
                      finding the best solutions means taking the road less
                      travelled. We’re not afraid to experiment, to try new
                      things or make mistakes along the way. Learning through
                      doing keeps us sharp.{' '}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <img src={IMAGES.aboutgif} className="img-fluid w-100" alt="" />
              </div>
            </div>

            {!isTab && (
              <div className="page-grid">
                <div className="grid-content grid-content--br">
                  <h3 className="heading heading--lg color-white">
                    Learning through doing
                  </h3>

                  <div className="min-grid-content">
                    <p className="paragraph">
                      We approach every problem with an open mind, and sometimes
                      finding the best solutions means taking the road less
                      travelled. We&apos;re not afraid to experiment, to try new
                      things or make mistakes along the way. Learning through
                      doing keeps us sharp.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="grid-content grid-content--bb">
                    <h3 className="heading heading--lg color-white">
                      Listen smart
                    </h3>

                    <div className="min-grid-content">
                      <p className="paragraph">
                        We take an open-door approach to communication, with
                        both our clients and each other. Everyone’s feedback is
                        valuable, and we’re never too busy to hear it. Listening
                        smart is not just hearing, it&apos;s taking the time to
                        truly understand, without ego, and be willing and ready
                        to adapt.
                      </p>
                    </div>
                  </div>

                  <div className="grid-content">
                    <h3 className="heading heading--lg color-white">
                      Challenge core assumptions
                    </h3>

                    <div className="min-grid-content">
                      <p className="paragraph">
                        This idea remains the starting point for everything we
                        do. We unlock new solutions by challenging both our
                        clients and our own assumptions about a product, an
                        audience, and what is possible. This idea was born out
                        of the very foundation of Spencerzill. Many thought us
                        incapable of running a successful advertising company
                        but we challenge that speculation everyday.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        {!isTab && (
          <section className="section">
            <div className="container container-mobile">
              <div className="section--lg approach">
                <h3 className="heading heading--lg color-white mb-5">
                  Our Approach
                </h3>

                <div className="extra-left-padding">
                  <p className="paragraph color-white pb-4">
                    We shape brands through a process of exploration and
                    investigation. We design to simplify we create to solve,
                    fostering a human-centric ideology to achieve lasting
                    solutions backed by modern technologies while exploreing
                    unique motion design techniques. We build solutions that
                    improve functionality, stay reliable, and evolve with time.
                    Like the world around us and the businesses we work with,
                    our design and production practice is always striving to
                    improve.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="section section--lg about-clients">
          <img src={Bg} className="clients-img-bg" />
          <div className="container container-mobile">
            <div className="mw-1000 mb-5">
              <h3 className="heading heading--lg color-white text-center">
                Selected Clients
              </h3>
              <p className="paragraph text-center pb-5">
                since 2012, we&apos;ve partnered with more <br /> than 200
                brands
              </p>
            </div>

            <div className="text-center pt-5 pb-5">
              <img src={IMAGES.aboutclients} className="img-fluid" alt="" />
            </div>
          </div>
        </section>

        {!isTab && (
          <section className="section section--lg instagram-section">
            <div className="container-fluid container-mobile">
              <div className="mw-750 mb-5">
                <p className="paragraph paragraph--lg color-white font-size-large font-bold text-center mb-0">
                  Find out what we are up to…
                </p>

                <p className="paragraph color-white paragraph--lg text-center follow">
                  Follow us on{' '}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/spencer_zill/?hl=en"
                    className="about-social__link"
                    title="Instagram"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                  ,{' '}
                  <a
                    target="_blank"
                    href="https://web.facebook.com/Bryan.Anoruo"
                    className="about-social__link"
                    title="Facebook"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                  ,{' '}
                  <a
                    target="_blank"
                    href="https://vimeo.com/512268401"
                    title="Vimeo"
                    className="about-social__link"
                    rel="noreferrer"
                  >
                    Vimeo
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    href=""
                    title="Behance"
                    className="about-social__link"
                    rel="noreferrer"
                  >
                    Behance
                  </a>{' '}
                  for inside stories, latest projects, thoughts, and behind the
                  scenes from the SpencerZill studio.
                </p>
              </div>

              <div className="mw-1440">
                <div className="row pt-4 no-gutters">
                  {SOCIAL_IMAGES.map((item, index) => (
                    <div className="col-md-2 mb-3" key={index}>
                      <a
                        href={item.link}
                        className={classnames(
                          'about-social',
                          `slant--${item.slant_type}`
                        )}
                      >
                        <CustomImage
                          className="about-social-image"
                          src={item.image}
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </motion.div>
    </PageContainer>
  );
};

About.propTypes = {
  isTab: PropTypes.bool
};

export default About;
