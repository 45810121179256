// ontt project
import onttafter1 from 'assets/img/projects/ontt/after1.jpg';
import onttafter2 from 'assets/img/projects/ontt/after2.png';
import onttbefore1 from 'assets/img/projects/ontt/before1.jpg';
import onttbefore2 from 'assets/img/projects/ontt/before2.png';
import onttgif1 from 'assets/img/projects/ontt/gif1.gif';
import onttgif2 from 'assets/img/projects/ontt/gif2.gif';
import onttlowt from 'assets/img/projects/ontt/lowt.gif';
import onttheader from 'assets/img/projects/ontt/header.png';
import onttmood1 from 'assets/img/projects/ontt/mood1.png';
import onttmood2 from 'assets/img/projects/ontt/mood2.png';
import onttmood3 from 'assets/img/projects/ontt/mood3.png';
import onttmood4 from 'assets/img/projects/ontt/mood4.png';
import onttmood5 from 'assets/img/projects/ontt/mood5.png';
import ontt2 from 'assets/img/projects/ontt/ontt2.png';
import ontt3 from 'assets/img/projects/ontt/ontt3.png';
import ontt4 from 'assets/img/projects/ontt/ontt4.png';
import ontt5 from 'assets/img/projects/ontt/ontt5.png';
import ontt6 from 'assets/img/projects/ontt/ontt6.png';
import ontt7 from 'assets/img/projects/ontt/ontt7.png';
import ontt8 from 'assets/img/projects/ontt/ontt8.png';
import ontt9 from 'assets/img/projects/ontt/ontt9.png';
import ontt10 from 'assets/img/projects/ontt/ontt10.png';
import ontt11 from 'assets/img/projects/ontt/ontt11.png';
import ontt12 from 'assets/img/projects/ontt/ontt12.png';
import ontt13 from 'assets/img/projects/ontt/ontt13.png';
import ontt14 from 'assets/img/projects/ontt/ontt14.png';
import ontt15 from 'assets/img/projects/ontt/ontt15.png';
import ontt16 from 'assets/img/projects/ontt/ontt16.png';
import ontt17 from 'assets/img/projects/ontt/ontt17.png';
import ontt18 from 'assets/img/projects/ontt/ontt18.png';
import ontt19 from 'assets/img/projects/ontt/ontt19.png';
import ontt20 from 'assets/img/projects/ontt/ontt20.png';
import ontt21 from 'assets/img/projects/ontt/ontt21.png';
import ontt22 from 'assets/img/projects/ontt/ontt22.png';
import ontt23 from 'assets/img/projects/ontt/ontt23.jpg';
import ontt24 from 'assets/img/projects/ontt/ontt24.jpg';
import onttsketch1 from 'assets/img/projects/ontt/sketch1.png';
import onttsketch2 from 'assets/img/projects/ontt/sketch2.png';
import onttsketch3 from 'assets/img/projects/ontt/sketch3.png';
import ontttype1 from 'assets/img/projects/ontt/type1.png';

const ONTT_IMAGES = {
  onttafter1,
  onttafter2,
  onttbefore1,
  onttbefore2,
  onttgif1,
  onttgif2,
  onttlowt,
  onttheader,
  onttmood1,
  onttmood2,
  onttmood3,
  onttmood4,
  onttmood5,
  ontt2,
  ontt3,
  ontt4,
  ontt5,
  ontt6,
  ontt7,
  ontt8,
  ontt9,
  ontt10,
  ontt11,
  ontt12,
  ontt13,
  ontt14,
  ontt15,
  ontt16,
  ontt17,
  ontt18,
  ontt19,
  ontt20,
  ontt21,
  ontt22,
  ontt23,
  ontt24,
  onttsketch1,
  onttsketch2,
  onttsketch3,
  ontttype1
};

export default ONTT_IMAGES;
