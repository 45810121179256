import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { PageContainer } from 'layouts/PageContainer';
import { EXPERIMENT_IMAGES as IMAGES, HOME_IMAGES } from 'utility/images';
import { ANIMATION_DEFAULTS, VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { CustomImage, HeroSection, MetaDecorator } from 'components';
import Bg from 'assets/img/bottom-bg.png';

const Playground = ({ isMobile, isTab }) => {
  return (
    <PageContainer pageClass="playground" isTab={isTab}>
      <MetaDecorator
        title="Playground - SpencerZill"
        description="Some of SpencerZill's ideas, research and curious experiments"
      />

      <motion.div
        exit="out"
        initial="out"
        animate="in"
        variants={ANIMATION_DEFAULTS.pageTransition}
        transition={ANIMATION_DEFAULTS.duration}
      >
        <HeroSection title={`Experiments`} />

        <section className="section">
          <div className="container container-mobile">
            <div className="row">
              <div className="col-md-5">
                <p className="paragraph playground__title">
                  Ideas, research
                  <span>&amp; curiosities along the way.</span>
                </p>
              </div>

              <div className="col-md-7 pb-4">
                <p className="paragraph paragraph--lg color-off-white">
                  During the years of busy production schedule in our studios,
                  we often design &amp; let go of some really cool animations,
                  ideas and technical test that are just too nice to forget.
                </p>
                <p className="paragraph paragraph--lg">
                  Explorations in motion says it all. This is a collection of
                  solution driven still and motion tests compiled as one short
                  non commercial digital spreadsheed. We endeavoured to create
                  an original, didactic, and offbeat universe - The playground.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={classnames('section', { 'pb-0': isMobile })}>
          <div className="container-fluid playground-container">
            <div className="row no-gutters">
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment1}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment17}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment18}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment6}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment20}
                  className="playground-image playground-image--lg"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment4}
                  className="playground-image playground-image--lg"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-12">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment5}
                  className="img-fluid"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-5">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment19}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment37}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment12}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment21}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment34}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment9}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment45}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-12">
                <CustomImage
                  hasScrollEffect
                  src={HOME_IMAGES.projects.showreel}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment40}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment22}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-5">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment14}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment36}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-12">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment23}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment35}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment24}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment42}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment44}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment25}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment38}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment26}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-12">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/535566078"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment41}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment27}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment28}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 mb-5">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment29}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment30}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.experiment43}
                      className="playground-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment31}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-12">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment32}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment46}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment33}
                  className="playground-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="col-12">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.experiment16}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="be-seen section">
          <img src={Bg} className="img-bg" />

          <div className="container mw-1000">
            <h3 className="heading heading--lg heading--xl-nm color-white pb-5">
              Let it be seen!
            </h3>

            <p className="paragraph">
              We carefully consider every project we take on, because when we
              commit, we always bring 110%. For us, design is the ongoing
              pursuit of the perfect balance between simplicity and complexity.
            </p>
            <p className="paragraph pb-4">
              If we see a way to make something smarter, faster, better, we’ll
              always push for it even when it makes our job a bit harder! We’re
              driven by a desire to build great experiences shaped by data, and
              sometimes that means going above and beyond the brief.{' '}
              <span className={classnames({ 'color-primary': isMobile })}>
                Each project is an adventure.
              </span>
            </p>

            <p className="paragraph" style={{ opacity: isMobile ? 0.7 : 1 }}>
              We are always interested in creating cool stuff. Make sure to
              check back for more updates to the playground ):.
            </p>
          </div>
        </section>
      </motion.div>
    </PageContainer>
  );
};

Playground.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool
};

export default Playground;
