import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomImage } from 'components/CustomImage';
import { CustomVideo } from 'components/CustomVideo';
import { CLUBHOUSE_PROJECT_DATA as DATA } from 'utility/constants/project-data';
import ReactPlayer from 'react-player';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';

class GridSections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxLength: 10,
      hasMore: true,
      media: DATA.media.slice(0, 10)
    };

    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.groupByRow = this.groupByRow.bind(this);
    this.generateRows = this.generateRows.bind(this);
  }

  fetchMoreData() {
    if (this.state.media.length === DATA.media.length) {
      this.setState({ hasMore: false });
      return;
    }

    setTimeout(() => {
      const newLength = this.state.maxLength + 10;

      this.setState({
        maxLength: newLength,
        media: DATA.media.slice(0, newLength)
      });
    }, 1500);
  }

  getRowById(id) {
    return DATA.rows.filter((item) => item.id === id)[0];
  }

  groupByRow(mediaArr) {
    const obj = {};

    const rows = [...new Set(mediaArr.map((item) => item.rowId))];

    rows.forEach((rowId) => {
      const arr = mediaArr.filter((elem) => elem.rowId === rowId);
      obj[rowId] = arr;
    });

    return { rows, mediaObj: obj };
  }

  generateColumns(row, desktopClass, mobileClass) {
    const { media } = this.state;

    return this.groupByRow(media).mediaObj[row].map((item, j) => (
      <div
        className={classnames(item.parentClass, {
          [desktopClass]: !this.props.isMobile,
          [mobileClass]: this.props.isMobile
        })}
        key={j}
      >
        {item.type === 'image' && (
          <CustomImage
            hasScrollEffect={item.hasScrollEffect}
            src={item.src}
            srcLarge={item.srcLarge}
            className={item.class}
            height="100%"
            alt=""
          />
        )}

        {item.type === 'video' && (
          <CustomVideo src={item.src} type="clubhouse" {...item.props} />
        )}

        {item.type === 'react-player' && (
          <ReactPlayer
            url={item.src}
            className={item.class}
            width={VIDEO_PLAYER_SETTINGS.width}
            height={VIDEO_PLAYER_SETTINGS.height}
            config={VIDEO_PLAYER_SETTINGS.config}
            muted={true}
            controls={true}
            loop={true}
          />
        )}
      </div>
    ));
  }

  generateRows(row) {
    const mediaRow = this.getRowById(row);
    const { viewport, desktopClass, mobileClass } = mediaRow;

    if (!viewport) {
      return (
        <div
          className={classnames(mediaRow.class, {
            [desktopClass]: !this.props.isMobile,
            [mobileClass]: this.props.isMobile
          })}
        >
          {this.generateColumns(row, desktopClass, mobileClass)}
        </div>
      );
    }

    if (!this.props.isMobile && viewport === 'desktop') {
      return (
        <div
          className={classnames(mediaRow.class, viewport, {
            [desktopClass]: !this.props.isMobile,
            [mobileClass]: this.props.isMobile
          })}
        >
          {this.generateColumns(row, desktopClass, mobileClass)}
        </div>
      );
    }

    if (this.props.isMobile && viewport === 'mobile') {
      return (
        <div
          className={classnames(mediaRow.class, viewport, {
            [desktopClass]: !this.props.isMobile,
            [mobileClass]: this.props.isMobile
          })}
        >
          {this.generateColumns(row, desktopClass, mobileClass)}
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <InfiniteScroll
          dataLength={this.state.media.length}
          next={this.fetchMoreData}
          endMessage={
            <p className="color-white text-center">
              <b>Yay! You have seen it all</b>
            </p>
          }
          hasMore={this.state.hasMore}
          loader={
            <h4 className="color-white text-center section pt-0">Loading...</h4>
          }
        >
          <div className="section">
            <div className="container-fluid p-0 mw-1440">
              {this.groupByRow(this.state.media).rows.map((row, i) => (
                <div key={i}>{this.generateRows(row)}</div>
              ))}
            </div>
          </div>
        </InfiniteScroll>

        {!this.state.hasMore && (
          <div>
            <div className="section pt-0">
              <div className="container-fluid p-0 mw-1440">
                <div className="mw-750">
                  <div className="mb-4">
                    <h3 className="heading heading--lg">Results</h3>
                  </div>
                  <p className="paragraph color-off-white">
                    This visual branding goes from digital to physical
                    applications effortlessly without losing its global appeal.
                    It is adaptable and open to numerous interpretations.
                  </p>
                </div>
              </div>
            </div>

            <div className="section pt-0">
              <div className="container-fluid p-0 mw-1440">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/563262450"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

GridSections.propTypes = {
  isMobile: PropTypes.bool
};

export default GridSections;
