import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PageContainer } from 'layouts/PageContainer';
import { motion } from 'framer-motion';
import { Tabs, Tab } from 'react-bootstrap';
import 'animate.css';
import { Portfolios } from 'components/Portfolios';
import {
  PORTFOLIO,
  UPCOMING,
  CLIENTS,
  ANIMATION_DEFAULTS,
  PORTFOLIO_CATEGORIES
} from 'utility/constants';
import { CustomImage, HomeHero, MetaDecorator } from 'components';

const Home = ({ isMobile, isTab }) => {
  const [key, setKey] = useState('all');
  const [showMore, setShowMore] = useState(false);
  const [portfolio, setPortfolio] = useState(PORTFOLIO);
  const [maxLength, setMaxLength] = useState(14);
  const [filteredPortfolio, setFilteredPortfolio] = useState(
    PORTFOLIO.slice(0, maxLength)
  );

  useEffect(() => {
    if (isMobile) {
      setMaxLength(5);
      setFilteredPortfolio(PORTFOLIO.slice(0, 5));
      return;
    }

    if (isTab) {
      setMaxLength(6);
      setFilteredPortfolio(PORTFOLIO.slice(0, 6));
      return;
    }

    setMaxLength(14);
    setFilteredPortfolio(PORTFOLIO.slice(0, 14));
  }, [isMobile, isTab, maxLength]);

  const onFilter = (tabindex) => {
    setKey(tabindex);
    const arr =
      tabindex === 'all'
        ? [...PORTFOLIO]
        : PORTFOLIO.filter((item) => item.category.includes(tabindex));

    setPortfolio(arr);
    setFilteredPortfolio(showMore ? arr : arr.slice(0, maxLength));
  };

  const toggleShowMore = () => {
    if (showMore) {
      setFilteredPortfolio([...portfolio].slice(0, maxLength));
    } else {
      setFilteredPortfolio([...portfolio]);
    }
    setShowMore((prevState) => !prevState);
  };

  return (
    <PageContainer
      pageClass="home page-body"
      navHidden={isMobile}
      isTab={isTab}
    >
      <MetaDecorator
        description="You can find
                  SpencerZill in a variety of projects for local, worldwide
                  brands and agencies"
        title="Home - SpencerZill"
      />

      <motion.div
        exit="out"
        initial="out"
        animate="in"
        variants={ANIMATION_DEFAULTS.pageTransition}
        transition={ANIMATION_DEFAULTS.duration}
      >
        <section className="hero">
          <HomeHero isMobile={isMobile} />
        </section>

        {!isMobile && (
          <section className="section section--lg">
            <div className="container">
              <div className="mw-750">
                <h3 className="heading heading--lg text-center">
                  Making impossible things exist
                </h3>
                <p className="paragraph text-center">
                  Movies, trailers, branding, commercials. You can find
                  SpencerZill in a variety of projects for local, worldwide
                  brands and agencies. If you haven’t seen what SpencerZill can
                  do yet, I invite you to take a look at SpencerZill portfolio.
                </p>
              </div>
            </div>
          </section>
        )}

        <section className="section portfolios pt-0">
          {isTab ? (
            <>
              <Portfolios isTab={isTab} data={filteredPortfolio} />
            </>
          ) : (
            <div className="container-fluid p-0">
              <Tabs
                id="portfolio-tabs"
                activeKey={key}
                transition={false}
                onSelect={(k) => onFilter(k)}
                className="tabs"
              >
                {PORTFOLIO_CATEGORIES.map((category, i) => (
                  <Tab
                    eventKey={category.toLowerCase()}
                    key={i}
                    title={category}
                  >
                    <Portfolios isTab={isTab} data={filteredPortfolio} />
                  </Tab>
                ))}
              </Tabs>
            </div>
          )}

          {portfolio.length > maxLength && (
            <div className="readmore-container mt-4">
              <div className="readmore" onClick={toggleShowMore}>
                <p>{showMore ? 'Less' : 'More'}</p>
                <div className="readmore-icon">
                  <span
                    className={classnames('icon-long-arrow', {
                      'rotate-span': showMore
                    })}
                  ></span>
                </div>
              </div>
            </div>
          )}
        </section>

        <section className="section pb-0 upcoming">
          <div className="container-fluid p-0">
            <h3 className="heading heading--lg text-center mb-5">
              {isTab ? 'Soon' : 'Upcoming Works'}{' '}
              {isTab && <span>cometh!</span>}
            </h3>

            <div className="row no-gutters">
              {UPCOMING.map((item, index) => (
                <div
                  className={classnames('col-md-4', {
                    'mb-0': !isTab || !isMobile,
                    'mb-5': isTab || isMobile
                  })}
                  key={index}
                >
                  <div className={classnames(`slant--${item.slant_type}`)}>
                    <CustomImage
                      src={item.image}
                      className="home-portfolio-img"
                      alt="upcoming"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="section section--lg bg-black">
          <div className="container container-mobile">
            {/* {!isTab && (
              <div className="mw-750 mb-5">
                <h3 className="heading text-center font-bold">
                  Where would we be without our clients?
                </h3>

                <p className="paragraph text-center">
                  Here are Some of the clients we have been working with
                  recently. SpencerZill has been so lucky to work with
                  inspirational clients and amazing businesses over the past
                  years, many of whom we are now happy to call friends.
                </p>
              </div>
            )} */}
            <section>
              <section className="section section--lg">
                <div className="container">
                  <div className="">
                    <h3 className="heading heading--lg text-center">
                      Awards & Recognitions
                    </h3>
                    <div className="row mw-750" style={{ marginTop: '50px' }}>
                      <div className="col-md-4">
                        <img
                          src="https://res.cloudinary.com/dejithecoder/image/upload/v1665665876/01.png"
                          alt="Award recognition"
                          // className="w-100"
                          style={{ height: '80px' }}
                        />
                        <p style={{ fontSize: '16px', marginTop: '20px' }}>
                          DesignRush, known for its
                          <a
                            href="https://www.designrush.com/best-designs/print"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <span
                              style={{
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                                textDecorationColor: '#1993E3',
                                color: '#1993E3'
                              }}
                            >
                              print design awards
                            </span>
                          </a>{' '}
                          has chosen our Design Solution for Raven Bank to be
                          featured among{' '}
                          <b>The Best Promotional Print Designs.</b>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src="https://res.cloudinary.com/dejithecoder/image/upload/v1665663576/02.png"
                          alt="Award recognition"
                          style={{ height: '80px' }}
                        />
                        <p style={{ fontSize: '16px', marginTop: '20px' }}>
                          Our Design Solution for <b>Raven Bank</b> was awarded
                          by Behance as The Best of Behance Brand Identities.
                          Thanks, Behance!
                        </p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src="https://res.cloudinary.com/dejithecoder/image/upload/v1665663576/03.png"
                          alt="Award recognition"
                          style={{ height: '80px' }}
                        />
                        <p style={{ fontSize: '16px', marginTop: '20px' }}>
                          Our Design Solution for <b>Clubhouse</b> was awarded
                          by Behance as a Distinct Brand Identity. Thanks,
                          Behance!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>

            <div className="client-container">
              {CLIENTS.map((client, i) => (
                <div className={classnames('client mb-5')} key={i}>
                  <img src={client.image} alt="" />
                </div>
              ))}
            </div>
          </div>
        </section>
      </motion.div>
    </PageContainer>
  );
};

Home.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool
};

export default Home;
