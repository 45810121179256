import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useEventListener from 'services/useEventListener';
import { Header } from './Header';
import { Footer } from './Footer';

export const PageContainer = (props) => {
  const [showScrollButton, setScrollButton] = useState(false);
  const scrollref = useRef(null);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrollButton(true);
    } else {
      setScrollButton(false);
    }
  };

  useEventListener('scroll', handleScroll);

  return (
    <>
      <Header navHidden={props.navHidden} isTab={props.isTab} />

      <main className={classnames('page-body', props.pageClass)}>
        {props.children}
      </main>

      <Footer />

      <div
        onClick={scrollToTop}
        className={classnames('caret__block', { active: showScrollButton })}
        ref={scrollref}
      >
        <span className="caret__down"></span>
        <br />
        <span className="caret__text">Back To Top</span>
      </div>
    </>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  isTab: PropTypes.bool,
  navHidden: PropTypes.bool,
  pageClass: PropTypes.string
};
