import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Home from 'pages/Home';
import About from 'pages/About';
import Playground from 'pages/Playground';
import Project from 'pages/Project';
import NotFound from 'pages/NotFound';
import { AnimatePresence } from 'framer-motion';
import PageNavigationListener from 'services/PageNavigationListener';
import { MobileContext } from 'contexts/MobileContext';
import { Loader } from './components';

const routes = [
  {
    path: '/',
    Component: Home
  },
  {
    path: '/about',
    Component: About
  },
  {
    path: '/playground',
    Component: Playground
  },
  {
    path: '/project/:name',
    Component: Project
  },
  {
    path: '/404',
    Component: NotFound
  }
];

const RouterComponent = () => {
  const [actPreload, setActPreload] = useState(true);
  const { isMobile, isTab } = useContext(MobileContext);

  useEffect(() => {
    const t = setTimeout(() => {
      setActPreload(false);
    }, 2000);

    return () => clearTimeout(t);
  });

  return (
    <>
      {actPreload && <Loader />}

      <Router>
        <PageNavigationListener />

        <Switch>
          {routes.map(({ path, Component }, i) => (
            <Route
              key={i}
              exact
              path={path}
              render={(routeProps) => (
                <AnimatePresence exitBeforeEnter>
                  <Component
                    {...routeProps}
                    isMobile={isMobile}
                    isTab={isTab}
                  />
                </AnimatePresence>
              )}
            />
          ))}

          <Redirect to="/404" />
        </Switch>
      </Router>
    </>
  );
};

export default RouterComponent;
