// views project
import viewextra1 from 'assets/img/projects/views/extra1.png';
import viewextra2 from 'assets/img/projects/views/extra2.png';
import viewextra3 from 'assets/img/projects/views/extra3.png';
import viewextra4 from 'assets/img/projects/views/extra4.png';
import viewextra5 from 'assets/img/projects/views/extra5.png';
import viewflick1 from 'assets/img/projects/views/flick1.png';
import viewflick2 from 'assets/img/projects/views/flick2.jpg';
import viewflick3 from 'assets/img/projects/views/flick3.png';
import viewflick4 from 'assets/img/projects/views/flick4.png';
import viewflick5 from 'assets/img/projects/views/flick5.png';
import viewfood1 from 'assets/img/projects/views/food1.png';
import viewfood2 from 'assets/img/projects/views/food2.png';
import viewfood3 from 'assets/img/projects/views/food3.png';
import viewfood4 from 'assets/img/projects/views/food4.png';
import viewfood5 from 'assets/img/projects/views/food5.png';
import viewheader from 'assets/img/projects/views/header.png';
import viewmood1 from 'assets/img/projects/views/mood1.png';
import viewmood2 from 'assets/img/projects/views/mood2.png';
import viewmood3 from 'assets/img/projects/views/mood3.png';
import viewmood4 from 'assets/img/projects/views/mood4.png';
import viewmood5 from 'assets/img/projects/views/mood5.png';
import viewmusic1 from 'assets/img/projects/views/music1.png';
import viewmusic2 from 'assets/img/projects/views/music2.png';
import viewmusic3 from 'assets/img/projects/views/music3.png';
import viewmusic4 from 'assets/img/projects/views/music4.png';
import viewmusic5 from 'assets/img/projects/views/music5.png';
import viewnative1 from 'assets/img/projects/views/native1.png';
import viewnative2 from 'assets/img/projects/views/native2.png';
import viewnative3 from 'assets/img/projects/views/native3.png';
import viewnative4 from 'assets/img/projects/views/native4.png';
import viewnative5 from 'assets/img/projects/views/native5.png';
import viewsketch1 from 'assets/img/projects/views/sketch1.jpg';
import viewsketch2 from 'assets/img/projects/views/sketch2.jpg';
import viewstyle1 from 'assets/img/projects/views/style1.png';
import viewstyle2 from 'assets/img/projects/views/style2.png';
import viewstyle3 from 'assets/img/projects/views/style3.png';
import viewstyle4 from 'assets/img/projects/views/style4.png';
import viewstyle5 from 'assets/img/projects/views/style5.png';
import viewtrend1 from 'assets/img/projects/views/trend1.png';
import viewtrend2 from 'assets/img/projects/views/trend2.png';
import viewtrend3 from 'assets/img/projects/views/trend3.png';
import viewtrend4 from 'assets/img/projects/views/trend4.png';
import viewtrend5 from 'assets/img/projects/views/trend5.png';
import viewvcstyle1 from 'assets/img/projects/views/vc-style1.png';
import viewvcstyle2 from 'assets/img/projects/views/vc-style2.png';
import viewvcstyle3 from 'assets/img/projects/views/vc-style3.png';
import viewvcstyle4 from 'assets/img/projects/views/vc-style4.png';
import viewvcstyle5 from 'assets/img/projects/views/vc-style5.png';
import view1 from 'assets/img/projects/views/views1.png';
import view2 from 'assets/img/projects/views/views2.png';
import view3 from 'assets/img/projects/views/views3.png';
import view4 from 'assets/img/projects/views/views4.png';
import view5 from 'assets/img/projects/views/views5.png';
import view6 from 'assets/img/projects/views/views6.png';
import view7 from 'assets/img/projects/views/views7.png';
import view8 from 'assets/img/projects/views/views8.png';
import draft1 from 'assets/img/projects/views/draft1.jpg';
import draft2 from 'assets/img/projects/views/draft2.jpg';
import draft3 from 'assets/img/projects/views/draft3.jpg';
import draft4 from 'assets/img/projects/views/draft4.jpg';

const VIEWS_CHANNEL_IMAGES = {
  viewextra1,
  viewextra2,
  viewextra3,
  viewextra4,
  viewextra5,
  viewflick1,
  viewflick2,
  viewflick3,
  viewflick4,
  viewflick5,
  viewfood1,
  viewfood2,
  viewfood3,
  viewfood4,
  viewfood5,
  viewheader,
  viewmood1,
  viewmood2,
  viewmood3,
  viewmood4,
  viewmood5,
  viewmusic1,
  viewmusic2,
  viewmusic3,
  viewmusic4,
  viewmusic5,
  viewnative1,
  viewnative2,
  viewnative3,
  viewnative4,
  viewnative5,
  viewsketch1,
  viewsketch2,
  viewstyle1,
  viewstyle2,
  viewstyle3,
  viewstyle4,
  viewstyle5,
  viewtrend1,
  viewtrend2,
  viewtrend3,
  viewtrend4,
  viewtrend5,
  viewvcstyle1,
  viewvcstyle2,
  viewvcstyle3,
  viewvcstyle4,
  viewvcstyle5,
  view1,
  view2,
  view3,
  view4,
  view5,
  view6,
  view7,
  view8,
  draft1,
  draft2,
  draft3,
  draft4
};

export default VIEWS_CHANNEL_IMAGES;
