import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import { Modal } from 'react-bootstrap';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';

export const VideoModal = ({ link, show, onClose }) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="xl"
      centered
    >
      <div className="modal-close close-icon" onClick={onClose}>
        <span></span>
        <span></span>
      </div>
      <Modal.Body>
        <div className="player-wrapper">
          <ReactPlayer
            url={link}
            className="react-player"
            width={VIDEO_PLAYER_SETTINGS.width}
            height={VIDEO_PLAYER_SETTINGS.height}
            config={VIDEO_PLAYER_SETTINGS.config}
            controls={true}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

VideoModal.propTypes = {
  link: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool
};
