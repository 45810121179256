// yellowstar project
import yellowstargif3 from 'assets/img/projects/yellowstar/explosion.gif';

import after from 'assets/img/projects/yellowstar/after.png';
import before from 'assets/img/projects/yellowstar/before.png';
import header from 'assets/img/projects/yellowstar/header.png';

import countdown1 from 'assets/img/projects/yellowstar/countdown1.png';
import countdown2 from 'assets/img/projects/yellowstar/countdown2.png';
import countdown3 from 'assets/img/projects/yellowstar/countdown3.png';
import countdown4 from 'assets/img/projects/yellowstar/countdown4.png';
import countdown5 from 'assets/img/projects/yellowstar/countdown5.png';
import countdown6 from 'assets/img/projects/yellowstar/countdown6.png';
import countdown8 from 'assets/img/projects/yellowstar/countdown8.png';
import countdown9 from 'assets/img/projects/yellowstar/countdown9.png';
import countdown10 from 'assets/img/projects/yellowstar/countdown10.png';
import countdown11 from 'assets/img/projects/yellowstar/countdown11.png';

import family1 from 'assets/img/projects/yellowstar/family1.png';
import family3 from 'assets/img/projects/yellowstar/family3.png';
import family4 from 'assets/img/projects/yellowstar/family4.png';

import logo1 from 'assets/img/projects/yellowstar/logo1.png';
import logo2 from 'assets/img/projects/yellowstar/logo2.png';
import logo3 from 'assets/img/projects/yellowstar/logo3.png';
import logo4 from 'assets/img/projects/yellowstar/logo4.png';
import logo5 from 'assets/img/projects/yellowstar/logo5.png';
import logo6 from 'assets/img/projects/yellowstar/logo6.png';
import logo7 from 'assets/img/projects/yellowstar/logo7.png';
import logo8 from 'assets/img/projects/yellowstar/logo8.png';
import logo9 from 'assets/img/projects/yellowstar/logo9.png';

import scene3 from 'assets/img/projects/yellowstar/scene3.png';
import scene4 from 'assets/img/projects/yellowstar/scene4.png';
import scene5 from 'assets/img/projects/yellowstar/scene5.png';
import scene6 from 'assets/img/projects/yellowstar/scene6.png';

import slide1 from 'assets/img/projects/yellowstar/slide1.png';
import slide2 from 'assets/img/projects/yellowstar/slide2.png';

import story1 from 'assets/img/projects/yellowstar/story1.png';
import story2 from 'assets/img/projects/yellowstar/story2.png';
import story3 from 'assets/img/projects/yellowstar/story3.png';
import story4 from 'assets/img/projects/yellowstar/story4.png';
import story5 from 'assets/img/projects/yellowstar/story5.png';
import story6 from 'assets/img/projects/yellowstar/story6.png';
import story7 from 'assets/img/projects/yellowstar/story7.png';

import yellowstar1 from 'assets/img/projects/yellowstar/yellowstar1.png';
import yellowstar2 from 'assets/img/projects/yellowstar/yellowstar2.png';
import yellowstar3 from 'assets/img/projects/yellowstar/yellowstar3.png';
import yellowstar4 from 'assets/img/projects/yellowstar/yellowstar4.png';
import yellowstar6 from 'assets/img/projects/yellowstar/yellowstar6.png';
import yellowstar7 from 'assets/img/projects/yellowstar/yellowstar7.png';
import yellowstar9 from 'assets/img/projects/yellowstar/yellowstar9.png';
import yellowstar11 from 'assets/img/projects/yellowstar/yellowstar11.png';
import yellowstar14 from 'assets/img/projects/yellowstar/yellowstar14.png';
import yellowstar15 from 'assets/img/projects/yellowstar/yellowstar15.png';
import yellowstar16 from 'assets/img/projects/yellowstar/yellowstar16.png';
import yellowstar19 from 'assets/img/projects/yellowstar/yellowstar19.png';
import yellowstar20 from 'assets/img/projects/yellowstar/yellowstar20.png';
import yellowstar21 from 'assets/img/projects/yellowstar/yellowstar21.png';
import yellowstar22 from 'assets/img/projects/yellowstar/yellowstar22.png';
import yellowstar23 from 'assets/img/projects/yellowstar/yellowstar23.png';
import yellowstar24 from 'assets/img/projects/yellowstar/yellowstar24.png';
import yellowstar25 from 'assets/img/projects/yellowstar/yellowstar25.png';
import yellowstar27 from 'assets/img/projects/yellowstar/yellowstar27.png';
import yellowstar28 from 'assets/img/projects/yellowstar/yellowstar28.png';
import yellowstar29 from 'assets/img/projects/yellowstar/yellowstar29.png';
import yellowstar30 from 'assets/img/projects/yellowstar/yellowstar30.png';
import yellowstar31 from 'assets/img/projects/yellowstar/yellowstar31.png';
import yellowstar33 from 'assets/img/projects/yellowstar/yellowstar33.png';
import yellowstar34 from 'assets/img/projects/yellowstar/yellowstar34.png';
import yellowstar37 from 'assets/img/projects/yellowstar/yellowstar37.png';
import yellowstar38 from 'assets/img/projects/yellowstar/yellowstar38.png';
import yellowstar40 from 'assets/img/projects/yellowstar/yellowstar40.png';
import yellowstar41 from 'assets/img/projects/yellowstar/yellowstar41.png';
import yellowstar42 from 'assets/img/projects/yellowstar/yellowstar42.png';
import yellowstar44 from 'assets/img/projects/yellowstar/yellowstar44.png';
import yellowstar46 from 'assets/img/projects/yellowstar/yellowstar46.png';
import yellowstar56 from 'assets/img/projects/yellowstar/yellowstar56.png';
import yellowstar58 from 'assets/img/projects/yellowstar/yellowstar58.png';
import yellowstar59 from 'assets/img/projects/yellowstar/yellowstar59.png';
import yellowstar60 from 'assets/img/projects/yellowstar/yellowstar60.png';
import yellowstar64 from 'assets/img/projects/yellowstar/yellowstar64.png';
import yellowstar65 from 'assets/img/projects/yellowstar/yellowstar65.png';
import yellowstar73 from 'assets/img/projects/yellowstar/yellowstar73.png';
import yellowstar74 from 'assets/img/projects/yellowstar/yellowstar74.png';

const YELLOWSTAR_IMAGES = {
  yellowstargif3,

  after,
  before,
  header,

  countdown1,
  countdown2,
  countdown3,
  countdown4,
  countdown5,
  countdown6,
  countdown8,
  countdown9,
  countdown10,
  countdown11,

  family1,
  family3,
  family4,

  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,

  scene3,
  scene4,
  scene5,
  scene6,

  slide1,
  slide2,

  story1,
  story2,
  story3,
  story4,
  story5,
  story6,
  story7,

  yellowstar1,
  yellowstar2,
  yellowstar3,
  yellowstar4,
  yellowstar6,
  yellowstar7,
  yellowstar9,

  yellowstar11,
  yellowstar14,
  yellowstar15,
  yellowstar16,
  yellowstar19,

  yellowstar20,
  yellowstar21,
  yellowstar22,
  yellowstar23,
  yellowstar24,
  yellowstar25,
  yellowstar27,
  yellowstar28,
  yellowstar29,

  yellowstar30,
  yellowstar31,
  yellowstar33,
  yellowstar34,
  yellowstar37,
  yellowstar38,

  yellowstar40,
  yellowstar41,
  yellowstar42,
  yellowstar44,
  yellowstar46,

  yellowstar56,
  yellowstar58,
  yellowstar59,

  yellowstar60,
  yellowstar64,
  yellowstar65,

  yellowstar73,
  yellowstar74
};

export default YELLOWSTAR_IMAGES;
