import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';
import GridSections from 'components/projects/clubhouse-sections/GridSections';
import { MetaDecorator } from 'components/MetaDecorator';

export const Clubhouse = ({ isMobile, projectInfo }) => {
  return (
    <>
      <MetaDecorator image="clubhouse.jpg" />

      <ProjectContainer projectInfo={projectInfo} className="clubhouse">
        <div className="section">
          <div className="container-fluid p-0 mw-1440">
            <div className="page-grid">
              <div className="grid-image">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.clubhouse.clubhouse1}
                  srcLarge={IMAGES.clubhouse.clubhouse1_2x}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="grid-image">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.clubhouse.clubhouse2}
                  srcLarge={IMAGES.clubhouse.clubhouse2_2x}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classnames({ section: !isMobile })}>
          <div className="container-fluid p-0 mw-1440">
            <div className="mb-5">
              <h3 className="heading heading--lg text-center">
                Brand Presentation Video
              </h3>
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/579956569"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>

        <GridSections isMobile={isMobile} />
      </ProjectContainer>
    </>
  );
};

Clubhouse.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
