// pop overview project
import popbreakdown1 from 'assets/img/projects/pop-overview/breakdown1.png';
import popbreakdown2 from 'assets/img/projects/pop-overview/breakdown2.png';
import popbreakdown3 from 'assets/img/projects/pop-overview/breakdown3.png';
import popbreakdown4 from 'assets/img/projects/pop-overview/breakdown4.png';
import popbreakdown5 from 'assets/img/projects/pop-overview/breakdown5.png';
import popbreakdown6 from 'assets/img/projects/pop-overview/breakdown6.png';
import popgif from 'assets/img/projects/pop-overview/gif.gif';
import popheader from 'assets/img/projects/pop-overview/header.png';
import pop1 from 'assets/img/projects/pop-overview/pop1.png';
import pop2 from 'assets/img/projects/pop-overview/pop2.png';
import pop3 from 'assets/img/projects/pop-overview/pop3.png';
import pop4 from 'assets/img/projects/pop-overview/pop4.png';
import pop5 from 'assets/img/projects/pop-overview/pop5.png';
import popstyle1 from 'assets/img/projects/pop-overview/style1.png';
import popstyle2 from 'assets/img/projects/pop-overview/style2.png';
import popstyle3 from 'assets/img/projects/pop-overview/style3.png';
import popstyle4 from 'assets/img/projects/pop-overview/style4.png';
import popstyle5 from 'assets/img/projects/pop-overview/style5.png';
import popstyle6 from 'assets/img/projects/pop-overview/style6.png';

const POPOVERVIEW_IMAGES = {
  popbreakdown1,
  popbreakdown2,
  popbreakdown3,
  popbreakdown4,
  popbreakdown5,
  popbreakdown6,
  popgif,
  popheader,
  pop1,
  pop2,
  pop3,
  pop4,
  pop5,
  popstyle1,
  popstyle2,
  popstyle3,
  popstyle4,
  popstyle5,
  popstyle6
};

export default POPOVERVIEW_IMAGES;
