// geely project
import geely1 from 'assets/img/projects/geely/geely1.png';
import geely2 from 'assets/img/projects/geely/geely2.png';
import geely3 from 'assets/img/projects/geely/geely3.png';
import geely4 from 'assets/img/projects/geely/geely4.png';
import geely6 from 'assets/img/projects/geely/geely6.png';
import geely7 from 'assets/img/projects/geely/geely7.png';
import geely8 from 'assets/img/projects/geely/geely8.png';
import geely9 from 'assets/img/projects/geely/geely9.png';
import geely10 from 'assets/img/projects/geely/geely10.png';
import geely11 from 'assets/img/projects/geely/geely11.png';
import geely12 from 'assets/img/projects/geely/geely12.png';
import geely13 from 'assets/img/projects/geely/geely13.png';
import geely14 from 'assets/img/projects/geely/geely14.png';
import geely15 from 'assets/img/projects/geely/geely15.png';
import geely16 from 'assets/img/projects/geely/geely16.png';
import geely17 from 'assets/img/projects/geely/geely17.png';
import geely18 from 'assets/img/projects/geely/geely18.png';
import geely19 from 'assets/img/projects/geely/geely19.png';
import geely20 from 'assets/img/projects/geely/geely20.png';
import geely21 from 'assets/img/projects/geely/geely21.png';
import geely22 from 'assets/img/projects/geely/geely22.png';
import geely23 from 'assets/img/projects/geely/geely23.png';
import geely24 from 'assets/img/projects/geely/geely24.png';
import geely25 from 'assets/img/projects/geely/geely25.png';
import geely26 from 'assets/img/projects/geely/geely26.png';
import geely28 from 'assets/img/projects/geely/geely28.png';
import geely29 from 'assets/img/projects/geely/geely29.png';
import geely30 from 'assets/img/projects/geely/geely30.png';
import geely31 from 'assets/img/projects/geely/geely31.png';
import geely32 from 'assets/img/projects/geely/geely32.png';
import geely33 from 'assets/img/projects/geely/geely33.png';
import geely34 from 'assets/img/projects/geely/geely34.png';
import geely35 from 'assets/img/projects/geely/geely35.png';
import geely36 from 'assets/img/projects/geely/geely36.png';
import geely37 from 'assets/img/projects/geely/geely37.png';
import geely38 from 'assets/img/projects/geely/geely38.png';

import header from 'assets/img/projects/geely/header.png';

import spark from 'assets/img/projects/geely/spark.gif';

const GEELY_IMAGES = {
  geely1,
  geely2,
  geely3,
  geely4,
  geely6,
  geely7,
  geely8,
  geely9,

  geely10,
  geely11,
  geely12,
  geely13,
  geely14,
  geely15,
  geely16,
  geely17,
  geely18,
  geely19,

  geely20,
  geely21,
  geely22,
  geely23,
  geely24,
  geely25,
  geely26,
  geely28,
  geely29,

  geely30,
  geely31,
  geely32,
  geely33,
  geely34,
  geely35,
  geely36,
  geely37,
  geely38,
  header,
  spark
};

export default GEELY_IMAGES;
