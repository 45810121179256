import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useEventListener from 'services/useEventListener';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { HOME_IMAGES } from 'utility/images';
import { VideoModal } from './VideoModal';
import { CustomImage } from './CustomImage';
import { FadeInWhenVisible } from './FadeInVisible';

export const Portfolio = ({
  item,
  selectedPortfolio,
  setSelectedPortfolio,
  isTab
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');

  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const cursorRef = useRef(null);

  const springConfig = { damping: 25, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const moveCursor = (e) => {
    cursorX.set(e.clientX - 16);
    cursorY.set(e.clientY - 16);
  };

  const handleClick = (link, key) => {
    if (!link) {
      return;
    }

    if (key === 0) {
      setSelectedLink(link);
      setShowModal(true);

      return;
    }

    if (link.includes('https:')) {
      return window.open(link);
    }

    return history.push(link);
  };

  useEventListener('mousemove', moveCursor);

  return (
    <>
      <motion.div
        className={item.class}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() =>
          item.id === 1
            ? handleClick('https://vimeo.com/512268401', 0)
            : setSelectedPortfolio(item.id)
        }
      >
        <div
          className={classnames('portfolio', `slant--${item.slant_type}`, {
            active: isTab && selectedPortfolio === item.id
          })}
        >
          <FadeInWhenVisible>
            {isHovered && item.imageGif && (
              <motion.div className="portfolio__gif-block">
                <img src={item.imageGif} className="portfolio__gif" />
              </motion.div>
            )}

            {isHovered && item.video && item.video.desktop && (
              <motion.video
                autoPlay
                muted
                loop
                id="portfolioVideo"
                preload="true"
                className="portfolio__video"
                playsInline
              >
                <source
                  src={
                    isTab
                      ? item.video.mobile
                        ? item.video.mobile
                        : item.video.desktop
                      : item.video.desktop
                  }
                  type="video/mp4"
                />

                {Object.keys(item.video).includes('webm') && (
                  <source type="video/webm" src={item.video.webm} />
                )}
              </motion.video>
            )}

            <motion.div className="portfolio__image-block">
              <CustomImage
                src={
                  isTab && item.title.toLowerCase() === 'yello star'
                    ? HOME_IMAGES.projects.yellowstarmobile
                    : item.image
                }
                className="portfolio__image"
                alt=""
                height="100%"
              />
            </motion.div>

            {isTab && item.id === 1 && (
              <div className="play-cursor">
                <span className="icon-play"></span>
              </div>
            )}

            {!isTab && !showModal && item.id === 1 && (
              <motion.div
                className="play-cursor"
                onClick={() => handleClick('https://vimeo.com/512268401', 0)}
                ref={cursorRef}
                style={{
                  translateX: cursorXSpring,
                  translateY: cursorYSpring
                }}
              >
                <span className="icon-play"></span>
              </motion.div>
            )}

            {item.title && item.title.toLowerCase() !== 'yello star' && (
              <div className="portfolio__overlay"></div>
            )}
          </FadeInWhenVisible>

          <div className="portfolio-inner">
            <div>
              <p className="portfolio__title">{item.title}</p>
            </div>

            {item.id !== 1 && (
              <>
                <div className="d-flex align-items-center">
                  {item.links.map((link, i) => (
                    <div
                      onClick={() => handleClick(link.link, i)}
                      className={classnames('portfolio__link link-hover')}
                      key={i}
                    >
                      <span
                        className={classnames('mr-2', {
                          'icon-play': i !== 1,
                          'icon-book': i === 1,
                          'icon-behance': i === 2,
                          'd-none': !link.link
                        })}
                      ></span>
                      <span>{link.name}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>

      <VideoModal
        link={selectedLink}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

Portfolio.propTypes = {
  isTab: PropTypes.bool,
  item: PropTypes.object,
  selectedPortfolio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedPortfolio: PropTypes.func
};
