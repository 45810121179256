import React from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { ONTT_VIDEOS as VIDEOS } from 'utility/videos';
import { CustomImage } from 'components';
import { FadeInWhenVisible } from 'components/FadeInVisible';
import { CustomVideo } from 'components/CustomVideo';

export const Ontt = ({ isMobile, isTab, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="mw-750">
          {isTab && (
            <div className="mb-5">
              <h3 className="heading heading--lg color-white">
                The Official Naija Top 10
              </h3>
            </div>
          )}

          <div className="mb-4">
            <h3 className="heading heading--lg">Simple but never simplistic</h3>
          </div>
          <p className="paragraph">
            MusicShow rebranding for a brand that is home for the best sounds
            from Africa. Inspired by the world of a combination of an
            afrocentric and futuristic look. The design and branding created is
            elegant and classic, yet unmistakably current. Precise and
            uncluttered, but not rigid nor stiff.
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0">
          <div className="row no-gutters before-after">
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image col--br mb-2"
                src={IMAGES.ontt.onttbefore1}
                height="auto"
                alt=""
              />
              <p
                className={classnames(
                  'heading color-white heading--lg text-center',
                  { paragraph: isMobile }
                )}
              >
                Before
              </p>
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image col--bl mb-2"
                src={IMAGES.ontt.onttafter1}
                height="auto"
                alt=""
              />
              <p
                className={classnames(
                  'heading color-white heading--lg text-center',
                  { paragraph: isMobile }
                )}
              >
                After
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            {!isMobile && (
              <div className="mb-5">
                <h3 className="heading heading--lg text-center">
                  Trailer Video
                </h3>
              </div>
            )}

            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/360601593"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-dark-green">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Initial Sketchs</h3>
          </div>

          <div className="row">
            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.onttsketch1}
                className="project-image"
                alt=""
              />
            </div>
            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.onttsketch2}
                className="project-image"
                alt=""
              />
            </div>
            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.onttsketch3}
                className="project-image"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {isMobile && (
        <div className="section pb-0">
          <div className="container-fluid mw-1440">
            <div className="mb-5">
              <h3 className="heading heading--lg text-center">Mood Board</h3>
            </div>

            <div className="row no-gutters">
              <div className="col-4 pb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.ontt.onttmood1}
                  className="project-image"
                  alt=""
                  height="100%"
                />
              </div>
              <div className="col-4">
                <div className="h-50 mb-0">
                  <CustomImage
                    hasScrollEffect
                    src={IMAGES.ontt.onttmood2}
                    className="project-image"
                    height="100%"
                    alt=""
                  />
                </div>
                <div className="h-50 mb-0">
                  <CustomImage
                    hasScrollEffect
                    src={IMAGES.ontt.onttmood3}
                    className="project-image"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="h-50 mb-0">
                  <CustomImage
                    hasScrollEffect
                    src={IMAGES.ontt.onttmood4}
                    className="project-image"
                    height="100%"
                    alt=""
                  />
                </div>
                <div className="h-50 mb-0">
                  <CustomImage
                    hasScrollEffect
                    src={IMAGES.ontt.onttmood5}
                    className="project-image"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="section pb-0">
        <div className="container-fluid mw-1440">
          <CustomVideo noPrefix src={VIDEOS.plain} />
        </div>
      </div>

      <div className="section pb-4">
        <div className="container-fluid mw-750">
          <div className="mb-10">
            <div className="mb-4">
              <h3 className="heading heading--lg">Approach</h3>
            </div>

            <p className="paragraph">
              SpencerZill started by creating an identity using custom
              typography to allow incorporation of musical instruments and
              gadgets to form the words and numbers. The use of neon lights and
              colours completes a clever, milennial and classic identity that
              will always have a wow effect for years to come. The process was
              started in creating a 2D Base to serve as a guide for the 3D
              Geometry.
            </p>
          </div>
        </div>

        <div className="container-fluid mw-1440">
          <CustomImage
            hasScrollEffect
            src={IMAGES.ontt.ontttype1}
            className="img-fluid"
          />
        </div>
      </div>

      <div className="section pt-0 pb-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.ontt.ontt2}
              className="img-fluid"
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt3}
                className="project-image"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt4}
                className="project-image"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomVideo noPrefix src={VIDEOS.front} />
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomVideo noPrefix src={VIDEOS.band} />
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomVideo noPrefix src={VIDEOS.mtv} />
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.ontt.ontt6}
              className="img-fluid"
            />
          </div>

          <div className="">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/554089340"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              MTV ONTT Show Opening Video
            </h3>
          </div>

          <div className="pt-3 mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/360600305"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.onttgif1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo noPrefix src={VIDEOS.comp2} />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0">
          <div className="mw-750">
            <div className="mb-4">
              <h3 className="heading heading--lg">Colour</h3>
            </div>

            <p className="paragraph pb-0">
              Shades of green where selected to represent the Nigeran Heritage
              and show the strive for ethical practices. Yellow to be used to
              grab attention and convey a sense of happiness.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.ontt.ontt7}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid">
          <div className="mw-750 mb-10">
            <div className="mb-4">
              <h3 className="heading heading--lg">Details / Style Frames</h3>
            </div>

            <p className="paragraph mb-0">
              Each frame was designed with the utmost attention to detail, as
              something to be contemplated and not just watched.
            </p>
          </div>
        </div>

        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomVideo noPrefix src={VIDEOS.comp1} />
          </div>

          <div className="row">
            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt9}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt10}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt11}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt12}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt13}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt14}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt15}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt16}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt17}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt18}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomVideo noPrefix src={VIDEOS.table} />
          </div>

          <FadeInWhenVisible>
            <ImgComparisonSlider>
              <img slot="before" width="100%" src={IMAGES.ontt.onttafter2} />
              <img slot="after" width="100%" src={IMAGES.ontt.onttbefore2} />
            </ImgComparisonSlider>
          </FadeInWhenVisible>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0">
          <div className="mw-750">
            <div className="mb-4">
              <h3 className="heading heading--lg">Lower third Design</h3>
            </div>

            <p className="paragraph mb-0">
              Also created a sleek and catchy music lower thirds with a
              different concept than normal. Most lower thirds are placed at the
              bottom left or right side of a screen. We decided to place ours at
              the centre of a screen, adding the music cover art and audio
              equalizer bars to fit the theme of the rebrand.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440 mb-5">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.ontt.ontt20}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.onttlowt}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt22}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt23}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.ontt.ontt24}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo noPrefix src={VIDEOS.trim1} />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo noPrefix src={VIDEOS.trim2} />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440 mb-5">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              Short Breakdown Video
            </h3>
          </div>

          <div className="pt-3">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/U3Ph7OTe3QI"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

Ontt.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
