import { PROJECT_IMAGES } from 'utility/images';

export const PROJECTS = [
  // 0. Test
  {
    id: 0,
    url: 'raven',
    name: '',
    header: {
      image: PROJECT_IMAGES.raven.image1,
      video: '',
      // 'https://res.cloudinary.com/https-spencerzill-com/video/upload/v1649709787/RavenBank/10_Scene_2a_ykspd0.mp4',
      // 'https://res.cloudinary.com/https-spencerzill-com/video/upload/v1649250435/RavenBank/03_Color_Palette_vv649u.mp4',
      mobile: ''
    },
    footer: {
      // title: 'This project was completed in 4 months.',
      // desc: `Every detail was carefully thought out and chosen to create an
      //         identity both dynamic and diverse to reflect all users. We created
      //         an identity to inspire effective communication, an opportunity to
      //         leave positive footprints in lives around the world.`
    },
    list: {
      types: [
        'Corporate Branding Test',
        'Design & Animation Test',
        '2d & 3d Illustration Test',
        'Product Overview Test',
        'Iconography Test',
        'Segmented Campaign Ads Test'
      ],
      roles: [
        'Production',
        'Art Direction',
        '2d & 3d Illustration',
        'Visual Effects',
        'Motion Design',
        'Brand Design'
      ],
      tools: [
        'Illustrator',
        'Photoshop',
        'Cinema 4D',
        'Redshift',
        'Davinci resolve',
        'Sketch'
      ]
    }
    // credits: [
    //   'Production - SpencerZill',
    //   'Creative Director - Isaac Sixtus Chizaram',
    //   'Art Director - Bryan Anoruo',
    //   'Motion Design - Bryan Anoruo',
    //   'Design/Strategy -Isaac Sixtus Chizaram, Bryan Anoruo',
    //   'Visual Effect - Bryan Anoruo',
    //   'RnD/Story - Bryan Anoruo, Isaac Sixtus Chizaram',
    //   'Sound Design - Ajani Jesutobi, Bryan Anoruo'
    // ]
  },

  // 1. clubhouse
  {
    id: 1,
    url: 'clubhouse',
    name: 'Clubhouse',
    header: {
      image: PROJECT_IMAGES.clubhouse.header,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1620075263/spencerzill/clubhouse.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 4 months.',
      desc: `Every detail was carefully thought out and chosen to create an
              identity both dynamic and diverse to reflect all users. We created
              an identity to inspire effective communication, an opportunity to
              leave positive footprints in lives around the world.`
    },
    list: {
      types: [
        'Corporate Branding',
        'Design & Animation',
        '2d & 3d Illustration',
        'Product Overview',
        'Iconography',
        'Segmented Campaign Ads'
      ],
      roles: [
        'Production',
        'Art Direction',
        '2d & 3d Illustration',
        'Visual Effects',
        'Motion Design',
        'Brand Design'
      ],
      tools: [
        'Illustrator',
        'Photoshop',
        'Cinema 4D',
        'Redshift',
        'Davinci resolve',
        'Sketch'
      ]
    },
    credits: [
      'Production - SpencerZill',
      'Creative Director - Isaac Sixtus Chizaram',
      'Art Director - Bryan Anoruo',
      'Motion Design - Bryan Anoruo',
      'Design/Strategy -Isaac Sixtus Chizaram, Bryan Anoruo',
      'Visual Effect - Bryan Anoruo',
      'RnD/Story - Bryan Anoruo, Isaac Sixtus Chizaram',
      'Sound Design - Ajani Jesutobi, Bryan Anoruo'
    ]
  },

  // 2. ontt
  {
    id: 2,
    url: 'ontt',
    name: 'Official Naija Top Ten',
    header: {
      image: PROJECT_IMAGES.ontt.onttheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1617735352/spencerzill/ontt.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 1 month and 2 weeks.',
      desc: 'MTV Base is the most popular TV channel in Africa, we worked closely with the team at MTV to redesign the most definitive music countdown in Africa for a fresh, modern and new look.'
    },
    list: {
      types: ['3D', 'TV Branding', 'Broadcast Package'],
      roles: [
        'Branding',
        'Art Direction',
        '3d Illustration',
        'Animation & VFX'
      ],
      tools: ['Cinema 4D', 'Octane', 'After Effects', 'Premier Pro']
    },
    credits: [
      'Production - Spencerzill',
      'Creative Director - Bryan Anoruo',
      'Art Director/ RnD - Bryan Anoruo',
      'Visual Effects Artist  - Bryan Anoruo',
      'Motion Design - Bryan Anoruo',
      'Music & Sound Design - Truth',
      'Story Board - Evelyn Dairo',
      'CGI/Animation - Bryan Anoruo, Youssef Gamal'
    ]
  },

  // 3. views channel
  {
    id: 3,
    url: 'views',
    name: 'Views Channel',
    header: {
      image: PROJECT_IMAGES.views.viewheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619019657/spencerzill/views-channel.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 2 months.',
      desc: 'The aim is to represent diversity and connection through different colours, shapes and patterns that combine to create a beautiful and positive environment, that best express each segment.'
    },
    list: {
      types: ['Branding', 'Animation', '3d Illustration', 'Visual Effects'],
      roles: ['Branding', 'Creative Direction', '3d Illustration', 'Animation'],
      tools: ['Cinema 4D', 'Octane, RedShift', 'After Effects', 'Premier Pro']
    },
    credits: [
      'Production - SpencerZill',
      'Creative Direction - Bryan Anoruo',
      'Art Director - Bryan Anoruo, Derick Kanu, Timenyin Gordon',
      'RnD & Story - Bryan Anoruo',
      'CGI/Animation/Rendering - Bryan Anoruo, Timenyin Gordon, Youssef',
      'Visual Effects Artist - Bryan Anoruo',
      'Concept Art - ',
      'Music & Sound Design - Samuel Peter Chukwuka'
    ]
  },

  // 4. central cast
  {
    id: 4,
    url: 'central-cast',
    name: 'Central Cast',
    header: {
      image: PROJECT_IMAGES.centralcast.castheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619019616/spencerzill/central-cast.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 2 weeks.',
      desc: 'For this project the aim was to create a visual story that expresses the search for people who are passionate enough to push their limits beyond talent to achieve their goals to be the next VJ.'
    },
    list: {
      types: ['Branding', 'Animation', '3d Illustration'],
      roles: [
        'Branding',
        'Creative Direction',
        '3d Illustration',
        'Animation',
        'Motion Design'
      ],
      tools: [
        'Cinema 4D',
        'RedShift',
        'After Effects',
        'Premier Pro',
        'Houdini'
      ]
    },
    credits: [
      'Production - Spencerzill',
      'Creative Director - Bryan Anoruo',
      'Design & Story - Bryan Anoruo',
      'Art Director/ RnD - Bryan Anoruo, Derick Kanu',
      'Visual Effects Artist - Bryan Anoruo',
      'VFX Supervisor - Bryan Anoruo',
      'Animation/Motion Design - Bryan Anoruo, Timenyin Gordon',
      'Music & Sound Design - Bryan Anoruo'
    ]
  },

  // 5. yellostar
  {
    id: 5,
    url: 'yellostar',
    name: 'Yello Star',
    header: {
      image: PROJECT_IMAGES.yellowstar.header,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619019684/spencerzill/yellowstar-desktop.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619019684/spencerzill/yellowstar-mobile.mp4'
    },
    footer: {
      title: 'This project was completed in 1 month 2 weeks.',
      desc: `The new visual identity is underpinned by a system of easy-to-use
      guidelines that we developed for Yello Star marketing team,
      empowering them to confidently use the brand in their communications
      to its full effect, without the daily support of an agency.`
    },
    list: {
      types: [
        'Branding',
        'Animation',
        '3d Illustration',
        'Visual Effects',
        'Motion Design'
      ],
      roles: ['Branding', 'Creative Direction', '3d Modeling', 'Animation'],
      tools: [
        'Cinema 4D',
        'Octane, RedShift',
        'After Effects',
        'Premier Pro',
        'Houdini',
        'Maya'
      ]
    },
    credits: [
      'Client - MTN',
      'Production - SpencerZill',
      'Design & Story - Bryan Anoruo',
      'Creative Director - Bryan Anoruo',
      'Art Direction - Bryan Anoruo, Hertz Fauzan',
      '2D & 3D Motion Design - Bryan Anoruo',
      'Visual effects supervisor/Artist - Bryan Anoruo',
      'CGI / Animation- Bryan Anoruo, Joe Youusef',
      'Sound Designer- Ajani Jesutobi',
      'Lighting & Rendering - Bryan Anoruo',
      'Storyboard Artist - Abraham Owunaka'
    ]
  },

  // 6. hyundai
  {
    id: 6,
    url: 'lords-gin',
    name: 'Hyundai',
    header: {
      image: PROJECT_IMAGES.lordsgin.ginheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1617735324/spencerzill/lords-gin.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 2 weeks.',
      desc: 'We wanted to create a nice contrast between the speed of the smoke emitting from the tires and the actual car itself. The edit turned out pretty dope.'
    },
    list: {
      types: [
        'Branding',
        'Animation',
        '3d Illustration',
        'Visual Effects',
        'Motion Design'
      ],
      roles: [
        'Branding',
        'Art/Creative Direction',
        '3d Illustration',
        'Animation'
      ],
      tools: ['Cinema 4D', 'Octane', 'After Effects', 'Cinema 4D']
    },
    credits: [
      'Production- SpencerZill',
      'Creative Direction - Bryan Anoruo',
      'Design/Story - Bryan Anoruo',
      'Visual Effects - Bryan Anoruo',
      'Look & feel Development - Bryan Anoruo',
      'CGI/Animation - Bryan Anoruo',
      'Story Boards - Evlyn Dairo',
      'Sound Design - Truth sounds'
    ]
  },

  // 7. geely
  {
    id: 7,
    url: 'geely',
    name: 'Emgrand 7',
    header: {
      image: PROJECT_IMAGES.geely.header,
      video: '',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 2 months.',
      desc: `The idea alternated between life action, CGI, VFX, and visual
        storytelling. Everything was done to evoke emotion and solve the
        targeted data. The world of tech is illustrated with simplified
        visuals and elegant minimalism.`
    },
    list: {
      types: ['Documentary', 'Design', 'Animation', '3d Illustration'],
      roles: [
        'Production',
        'Art Direction',
        '2d & 3d Illustration',
        'Visual Effects'
      ],
      tools: ['Cinema 4D', 'Octane', 'Davinci resolve', 'Indesign']
    },
    credits: [
      'Production - SpencerZill',
      'Executive Creative Director - Bryan Anoruo',
      'Sound Design - Prince Kingsley',
      'VFX Artist - Bryan Anoruo',
      'Motion Design - Bryan Anoruo',
      'Producer - Prince Kingsley, Jimi Jay',
      '3D Modeling & Rendering - Bryan Anoruo',
      'Art Director - Prince Kingsley, Wiliams Osaze',
      'Editing/Color Correction - Luke John, Prince Kingsley',
      'Design & Strategy - Bryan Anoruo, Prince Kingsley, William Osaze'
    ]
  },

  // 8. find popcentral
  {
    id: 8,
    url: 'find-popcentral',
    name: 'Find Popcentral',
    header: {
      image: PROJECT_IMAGES.findpopcentral.findpopheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619019628/spencerzill/find-popcentral.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 2 weeks.',
      desc: 'The aim is to represent diversity and connection through different colours, shapes and patterns that combine to create a beautiful and positive environment, that best express each segment.'
    },
    list: {
      types: ['Campaign', 'Branding', 'Animation', 'Advertising'],
      roles: ['Branding', 'Sound Design', '2d Illustration', 'Animation'],
      tools: ['Premier Pro', 'After Effects']
    },
    credits: [
      'Production- SpencerZill',
      'Creative Direction - Bryan Anoruo',
      'Design/Story - Bryan Anoruo, Prince Kingsley',
      'Art Director - Bryan Anoruo',
      'Look & feel Development - Bryan Anoruo',
      'Animation - Bryan Anoruo',
      'Story Boards - Evlyn Dairo',
      'Sound Design - Truth Sounds'
    ]
  },

  // 9. pop overview
  {
    id: 9,
    url: 'pop-overview',
    name: 'Pop Overview',
    header: {
      image: PROJECT_IMAGES.popoverview.popheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1620075251/spencerzill/pop-overview.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 1 month.',
      desc: 'One of the concepts we wanted to achieve was a seamless integration of CG into live action and have them interact with the CG model in a fun and dramatic way.  We hope you enjoyed it.'
    },
    list: {
      types: ['Motion Design', 'Branding', 'Animation', '3d Illustration'],
      roles: [
        'VFX Video Production',
        'Art Direction',
        '3d Illustration',
        'Color Grading',
        'Animation'
      ],
      tools: ['Cinema 4D', 'Octane', 'After Effects', 'Premier Pro']
    },
    credits: [
      'Production - Spencerzill',
      'Creative Director - Bryan Anoruo',
      'Art Director/ RnD - Bryan Anoruo',
      'Visual Effects Artist - Bryan Anoruo',
      'VFX Supervisor - Bryan Anoruo',
      'Videographer - Luke John',
      'Animation/Motion Design - Bryan Anoruo',
      'Music & Sound Design - RedBox'
    ]
  },

  // 10. stardom
  {
    id: 10,
    url: 'stardom',
    name: 'Stardom',
    header: {
      image: PROJECT_IMAGES.stardom.stardomheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1617734359/spencerzill/stardom.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 1 month.',
      desc: 'STARDOM! Is A show that is much more than yet another celebration of singing abilities, song writing skills or an unusual talent to produce music. It is the fusion of the various musical backgrounds of contestants to create new sounds without losing their individuality.'
    },
    list: {
      types: ['Branding', 'Animation', '3d Illustration'],
      roles: ['Branding', 'Art Direction', '3d Illustration', 'Animation'],
      tools: ['Cinema 4D', 'Octane', 'After Effects', 'Premier Pro']
    },
    credits: [
      'Production - Spencerzill',
      'Creative Director - Bryan Anoruo, Derick Kanu',
      'Art Director/ RnD - Bryan Anoruo',
      'Visual Effects Artist  - Bryan Anoruo',
      'Copy Writing/Story - Vickie Martins',
      'CGI/Animation - Bryan Anoruo, Joe Rabbit Hole',
      'Motion Design - Bryan Anoruo',
      'Music & Sound Design - Truth '
    ]
  },

  // 11. snap crackle base
  {
    id: 11,
    url: 'crackle-base',
    name: 'Crackle Base',
    header: {
      image: PROJECT_IMAGES.cracklebase.crackleheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1617735323/spencerzill/snap.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 2 months.',
      desc: 'We helped Mtvbase tell this story through a bold, energetic new identity. This project required a long, expertly managed project timeline. Not only did we build a whole new brand system to create content around, we got mega strategic with the animation style and pattern. '
    },
    list: {
      types: ['TV Show Branding', 'Design', 'Animation', '3d Illustration'],
      roles: ['Branding', 'Art Direction', '2d & 3d Illustration', 'Animation'],
      tools: ['Cinema 4D', 'Arnold', 'After Effects', 'Premier Pro']
    },
    credits: []
  },

  // 12. dj takeover
  {
    id: 12,
    url: 'dj-takeover',
    name: 'Dj Takeover',
    header: {
      image: PROJECT_IMAGES.djtakeover.djheader,
      video:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1620075543/spencerzill/dj-takeover.mp4',
      mobile: ''
    },
    footer: {
      title: 'This project was completed in 3 months.',
      desc: 'A lot of lessons were and techniques were learnt during this project, that have helped shape our skill level to understand light and neon.'
    },
    list: {
      types: ['Branding', 'Animation', '3d Illustration'],
      roles: ['Branding', 'Web Design', '3d Illustration', 'Animation'],
      tools: ['Cinema 4D', 'Arnold', 'After Effects', 'HTML/CSS']
    },
    credits: []
  }
];
// eND
