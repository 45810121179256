// find popcentral project
import findpopgif1 from 'assets/img/projects/find-popcentral/gif1.gif';
import findpopgif2 from 'assets/img/projects/find-popcentral/gif2.gif';
import findpopheader from 'assets/img/projects/find-popcentral/header.png';
import findpop1 from 'assets/img/projects/find-popcentral/pop1.png';
import findpop2 from 'assets/img/projects/find-popcentral/pop2.png';
import findpop3 from 'assets/img/projects/find-popcentral/pop3.png';
import findpop4 from 'assets/img/projects/find-popcentral/pop4.png';
import findpop5 from 'assets/img/projects/find-popcentral/pop5.png';
import findpop6 from 'assets/img/projects/find-popcentral/pop6.png';
import findpop7 from 'assets/img/projects/find-popcentral/pop7.png';
import findpop8 from 'assets/img/projects/find-popcentral/pop8.png';
import findpop9 from 'assets/img/projects/find-popcentral/pop9.png';
import findpop10 from 'assets/img/projects/find-popcentral/pop10.png';
import findpop11 from 'assets/img/projects/find-popcentral/pop11.png';
import findpop12 from 'assets/img/projects/find-popcentral/pop12.png';
import findpop13 from 'assets/img/projects/find-popcentral/pop13.jpg';
import findpop14 from 'assets/img/projects/find-popcentral/pop14.png';
import findpop15 from 'assets/img/projects/find-popcentral/pop15.png';
import findpop16 from 'assets/img/projects/find-popcentral/pop16.png';
import findpop17 from 'assets/img/projects/find-popcentral/pop17.png';
import findpop18 from 'assets/img/projects/find-popcentral/pop18.png';
import findpop19 from 'assets/img/projects/find-popcentral/pop19.png';
import findpop20 from 'assets/img/projects/find-popcentral/pop20.png';
import findpop21 from 'assets/img/projects/find-popcentral/pop21.png';
import findpop22 from 'assets/img/projects/find-popcentral/pop22.png';
import findpop23 from 'assets/img/projects/find-popcentral/pop23.png';

const FIND_POPCENTRAL_IMAGES = {
  findpopgif1,
  findpopgif2,
  findpopheader,
  findpop1,
  findpop2,
  findpop3,
  findpop4,
  findpop5,
  findpop6,
  findpop7,
  findpop8,
  findpop9,
  findpop10,
  findpop11,
  findpop12,
  findpop13,
  findpop14,
  findpop15,
  findpop16,
  findpop17,
  findpop18,
  findpop19,
  findpop20,
  findpop21,
  findpop22,
  findpop23
};

export default FIND_POPCENTRAL_IMAGES;
