import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ReactComponent as LogoIcon } from 'assets/svg/logo-icon.svg';
import Preview from 'assets/img/preview-image.png';
import { MEDIA_LOADING_COLORS } from 'utility/constants';
import { FadeInWhenVisible } from './FadeInVisible';

export const CustomImage = ({
  src,
  srcLarge,
  height,
  alt,
  width,
  className,
  hasScrollEffect,
  containerClass
}) => {
  const [loading, setLoading] = React.useState(true);

  const imgTag = () => (
    <img
      src={srcLarge || src || Preview}
      alt={alt}
      role="presentation"
      className={className}
      onLoad={() => setLoading(false)}
      onError={() => setLoading(false)}
    />
  );

  return (
    <div
      style={{
        position: 'relative',
        height: height || 'auto',
        width: width || '100%',
        minHeight: loading ? '40rem' : 'auto'
      }}
      className={containerClass}
    >
      {loading && (
        <SkeletonTheme
          color={MEDIA_LOADING_COLORS.main}
          highlightColor={MEDIA_LOADING_COLORS.highlight}
        >
          <div className="custom-image-skeleton">
            <LogoIcon className="custom-image-icon" />
            <Skeleton duration={1} height={400} width={width || '100%'} />
          </div>
        </SkeletonTheme>
      )}

      <LazyLoad once offset={100} height="100%" style={{ height: '100%' }}>
        {hasScrollEffect ? (
          <FadeInWhenVisible>{imgTag()}</FadeInWhenVisible>
        ) : (
          imgTag()
        )}
      </LazyLoad>
    </div>
  );
};

CustomImage.propTypes = {
  className: PropTypes.string,
  containerClass: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasScrollEffect: PropTypes.bool,
  srcLarge: PropTypes.string
};
