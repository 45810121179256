export const CLUBHOUSE_ROWS = [
  {
    id: 1,
    class: 'page-grid',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 2,
    class: 'page-grid',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 3,
    class: 'page-grid',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 4,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 5,
    class: 'page-grid',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 6,
    class: 'page-grid',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 7,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 8,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 9,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 10,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 11,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 12,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 13,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 14,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 15,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 16,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 17,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 18,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 19,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 20,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 21,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 22,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 23,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 24,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 25,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 26,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 27,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 28,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 29,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 30,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 31,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 32,
    class: '',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 33,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 34,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 35,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: 'desktop'
  },
  {
    id: 36,
    class: 'row no-gutters test',
    desktopClass: '',
    mobileClass: '',
    viewport: 'mobile'
  },
  {
    id: 37,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 38,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 39,
    class: 'row no-gutters',
    desktopClass: 'mb-5',
    mobileClass: 'mb-3',
    viewport: ''
  },
  {
    id: 40,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 41,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 42,
    class: 'row no-gutters',
    desktopClass: 'mt-5',
    mobileClass: 'mt-3',
    viewport: ''
  },
  {
    id: 43,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  },
  {
    id: 44,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: 'desktop'
  },
  {
    id: 45,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: 'mobile'
  },
  {
    id: 46,
    class: 'row no-gutters',
    desktopClass: '',
    mobileClass: '',
    viewport: ''
  }
];
