import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import ReactPlayer from 'react-player/lazy';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { CustomImage } from 'components';

export const CentralCast = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section section--lg">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <div className="mb-4">
              <h3 className="heading heading--lg">
                This was a really quick turn project
              </h3>
            </div>
            <p className="paragraph">
              How far can you stretch to reach your dreams? Central Cast is a VJ
              Search for aspiring TV presenters to become the next big thing on
              television.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <h3 className="heading heading--lg text-center">
            Central Cast Promo Video
          </h3>
          <div className="mt-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/553489969"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <h3 className="heading heading--lg text-center">
            Central Cast Show Montage
          </h3>
          <div className="mt-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/HC5vPT6YR8U"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pb-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Mood Board</h3>
          </div>

          <div className="row no-gutters">
            <div className="col-4 mb-5">
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.castmood1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4 mb-5">
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.castmood2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4 mb-5">
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.castmood3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section section--lg">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              Replacing blood with colourful slime gives viewers the information
              we want to share without being gory, the colourful hands represent
              diversity. It was stressful, it was fun, it turned out pretty
              creepy actually haha. Love it, hope you love it too?
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-3': isMobile,
              'mb-5': !isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.centralcast.cast1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.cast2}
                className="img-fluid"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.cast3}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="w-100">
            <CustomImage
              hasScrollEffect
              src={IMAGES.centralcast.cast4}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <h3 className="heading heading--lg text-center">Style Frames</h3>

          <div className="w-100 mb-5 mt-5">
            <CustomImage
              hasScrollEffect
              src={IMAGES.centralcast.caststyle1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-7', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.caststyle2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div className="col-5">
              <div
                className={classnames({
                  'mb-3': isMobile,
                  'mb-5': !isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.centralcast.caststyle3}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>

              <div
                className={classnames({
                  'mb-3': isMobile,
                  'mb-5': !isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.centralcast.caststyle4}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.caststyle5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-6', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.caststyle6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames({
              'mb-3': isMobile,
              'mb-5': !isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.centralcast.caststyle7}
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className={classnames({
              'mb-3': isMobile,
              'mb-5': !isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.centralcast.caststyle8}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <div className="mt-4">
              <h3 className="heading heading--lg">The Idea</h3>
            </div>

            <p className="paragraph">
              The idea of a hand stretching past its limit to grab the mic is to
              visually explain that in the cut throat world of entertainment and
              fame, you need to push boundaries, work harder and be the best at
              what you do in order to achieve the top spot.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div
              className={classnames('col-7', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.castidea1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-5">
              <div
                className={classnames({
                  'mb-3': isMobile,
                  'mb-5': !isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.centralcast.castidea2}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div
                className={classnames({
                  'mb-3': isMobile,
                  'mb-5': !isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.centralcast.castidea3}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className={classnames({
              'mb-3': isMobile,
              'mb-5': !isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.centralcast.castidea4}
              alt=""
              className="img-fluid"
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-5', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.castidea5}
                alt=""
                className="project-image"
                height="100%"
              />
            </div>
            <div
              className={classnames('col-7', {
                'mb-3': isMobile,
                'mb-5': !isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.centralcast.castidea6}
                alt=""
                className="project-image"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <h3 className="heading heading--lg text-center">Process Reel</h3>

          <div className="mt-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/itsN0LL4RfM"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

CentralCast.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
