// snap crackle and base project
import crackleheader from 'assets/img/projects/crackle-base/header.png';
import cracklestyle1 from 'assets/img/projects/crackle-base/style1.png';
import cracklestyle2 from 'assets/img/projects/crackle-base/style2.png';
import cracklestyle3 from 'assets/img/projects/crackle-base/style3.png';
import cracklestyle4 from 'assets/img/projects/crackle-base/style4.png';
import cracklestyle5 from 'assets/img/projects/crackle-base/style5.png';
import cracklestyle6 from 'assets/img/projects/crackle-base/style6.png';

const CRACKLE_IMAGES = {
  crackleheader,
  cracklestyle1,
  cracklestyle2,
  cracklestyle3,
  cracklestyle4,
  cracklestyle5,
  cracklestyle6
};

export default CRACKLE_IMAGES;
