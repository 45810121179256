// about us
import aboutgif from 'assets/img/about/idea.gif';
import about1 from 'assets/img/about/image1.png';
import about2 from 'assets/img/about/image2.png';
import about3 from 'assets/img/about/image3.png';
import about4 from 'assets/img/about/image4.png';
import about5 from 'assets/img/about/image5.png';
import about6 from 'assets/img/about/image6.png';
import about7 from 'assets/img/about/about1.png';
import aboutclients from 'assets/img/about/clients.png';
import whoweare from 'assets/img/about/who-we-are.png';

export const ABOUT_IMAGES = {
  aboutgif,
  about1,
  about2,
  about3,
  about4,
  about5,
  about6,
  about7,
  aboutclients,
  whoweare
};
