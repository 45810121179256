import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoSvg } from 'assets/svg/logo-icon.svg';
import Bg from 'assets/img/header.png';

export const HeroSection = ({ title }) => {
  return (
    <section className="section hero">
      <img src={Bg} className="hero-bg" />

      <div className="hero-container">
        <div className="hero-inner">
          <LogoSvg className="hero__icon" alt="hero__icon" />

          <div className="text-center">
            <p className="hero__title" data-text={title}>
              {title}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSection.propTypes = {
  title: PropTypes.string
};
