export const SLANT_KEY_MAP = [
  {
    desktop: {
      class: 'col-md-6',
      slant_type: 'right'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'right'
    }
  },
  {
    desktop: {
      class: 'col-md-6',
      slant_type: 'left'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'left'
    }
  },
  {
    desktop: {
      class: 'col-lg-4',
      slant_type: 'right'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'right'
    }
  },
  {
    desktop: {
      class: 'col-lg-4',
      slant_type: 'left-right'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'left'
    }
  },
  {
    desktop: {
      class: 'col-lg-4',
      slant_type: 'left'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'right'
    }
  },
  {
    desktop: {
      class: 'col-lg-8',
      slant_type: 'right'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'left'
    }
  },
  {
    desktop: {
      class: 'col-lg-4',
      slant_type: 'left'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'right'
    }
  },
  {
    desktop: {
      class: 'col-lg-4',
      slant_type: 'left'
    },
    mobile: {
      class: 'col-md-6',
      slant_type: 'left'
    }
  }
];

export const VIDEO_PLAYER_SETTINGS = {
  width: '100%',
  height: '100%',
  config: {
    youtube: {
      playerVars: { disablekb: 1 },
      showinfo: 0
    }
  }
};

export const ANIMATION_DEFAULTS = {
  duration: { duration: 1 },
  pageTransition: {
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  },
  projectTransition: {
    in: {
      opacity: 0,
      y: 0
    },
    out: {
      opacity: 0,
      y: '-100%'
    }
  }
};

export const MEDIA_LOADING_COLORS = {
  main: '#011401',
  highlight: '#283a28'
};
