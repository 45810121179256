// experiments
import experiment1 from 'assets/img/experiments/experiment1.png';
import experiment2 from 'assets/img/experiments/experiment2.png';
import experiment3 from 'assets/img/experiments/experiment3.png';
import experiment4 from 'assets/img/experiments/experiment4.png';
import experiment5 from 'assets/img/experiments/experiment5.png';
import experiment6 from 'assets/img/experiments/experiment6.png';
import experiment7 from 'assets/img/experiments/experiment7.png';
import experiment8 from 'assets/img/experiments/experiment8.png';
import experiment9 from 'assets/img/experiments/experiment9.png';
import experiment10 from 'assets/img/experiments/experiment10.png';
import experiment11 from 'assets/img/experiments/experiment11.png';
import experiment12 from 'assets/img/experiments/experiment12.png';
import experiment13 from 'assets/img/experiments/experiment13.png';
import experiment14 from 'assets/img/experiments/experiment14.png';
import experiment15 from 'assets/img/experiments/experiment15.png';
import experiment16 from 'assets/img/experiments/experiment16.png';
import experiment17 from 'assets/img/experiments/experiment17.png';
import experiment18 from 'assets/img/experiments/experiment18.png';
import experiment19 from 'assets/img/experiments/experiment19.png';
import experiment20 from 'assets/img/experiments/experiment20.png';
import experiment21 from 'assets/img/experiments/experiment21.png';
import experiment22 from 'assets/img/experiments/experiment22.png';
import experiment23 from 'assets/img/experiments/experiment23.png';
import experiment24 from 'assets/img/experiments/experiment24.png';
import experiment25 from 'assets/img/experiments/experiment25.png';
import experiment26 from 'assets/img/experiments/experiment26.png';
import experiment27 from 'assets/img/experiments/experiment27.png';
import experiment28 from 'assets/img/experiments/experiment28.png';
import experiment29 from 'assets/img/experiments/experiment29.png';
import experiment30 from 'assets/img/experiments/experiment30.png';
import experiment31 from 'assets/img/experiments/experiment31.png';
import experiment32 from 'assets/img/experiments/experiment32.png';
import experiment33 from 'assets/img/experiments/experiment33.png';
import experiment34 from 'assets/img/experiments/experiment34.gif';
import experiment35 from 'assets/img/experiments/experiment35.gif';
import experiment36 from 'assets/img/experiments/experiment36.gif';
import experiment37 from 'assets/img/experiments/experiment37.gif';
import experiment38 from 'assets/img/experiments/experiment38.gif';
import experiment39 from 'assets/img/experiments/experiment39.gif';
import experiment40 from 'assets/img/experiments/experiment40.gif';
import experiment41 from 'assets/img/experiments/experiment41.gif';
import experiment42 from 'assets/img/experiments/experiment42.gif';
import experiment43 from 'assets/img/experiments/experiment43.gif';
import experiment44 from 'assets/img/experiments/experiment44.gif';
import experiment45 from 'assets/img/experiments/experiment45.gif';
import experiment46 from 'assets/img/experiments/experiment46.gif';

export const EXPERIMENT_IMAGES = {
  experiment1,
  experiment2,
  experiment3,
  experiment4,
  experiment5,
  experiment6,
  experiment7,
  experiment8,
  experiment9,
  experiment10,
  experiment11,
  experiment12,
  experiment13,
  experiment14,
  experiment15,
  experiment16,
  experiment17,
  experiment18,
  experiment19,
  experiment20,
  experiment21,
  experiment22,
  experiment23,
  experiment24,
  experiment25,
  experiment26,
  experiment27,
  experiment28,
  experiment29,
  experiment30,
  experiment31,
  experiment32,
  experiment33,
  experiment34,
  experiment35,
  experiment36,
  experiment37,
  experiment38,
  experiment39,
  experiment40,
  experiment41,
  experiment42,
  experiment43,
  experiment44,
  experiment45,
  experiment46
};
