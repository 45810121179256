import React from 'react';
import ReactPlayer from 'react-player/youtube';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';

export const FindPopcentral = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              Find Popcentral is an awareness campaign where fans get to win
              Loads of amazing items, just by spotting a Pop Central flyer
              (e-copy/ hard copy), scan and win.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Final Video</h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://youtu.be/IWcEnLIM6Ic"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              We created a bold, engaging and authentic explainer video for
              Popcentral containing step by step instructions on how to properly
              scan to find Popcentral across all channels in a custom animated
              guide.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div
              className={classnames('col-7', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop1}
                alt=""
                className="project-image"
                height="100%"
              />
            </div>

            <div className="col-5">
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop2}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>

              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop3}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpop4}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop7}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop8}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpop9}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440 mb-10">
          <div className="mw-750">
            <h3 className="heading heading--lg text-center">
              Animated Count down
            </h3>
            <p className="paragraph text-center">
              Also designed and animated countdown graphics <br /> for both
              social and TV. The goal was to create something simple with a
              great balance of entertainment.
            </p>
          </div>
        </div>

        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100 nmt-4', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpopgif1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpopgif2}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpop10}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop11}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop12}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              For this project we created series of flyers and animated Videos
              to help spread the word and allow people to easily find and scan
              to find popcentral.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters fabulous">
            <div
              className={classnames('col-md-3 col-lg-2', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop13}
                className="project-image ppr"
                height={isMobile ? '100%' : '375px'}
                alt=""
              />
            </div>
            <div
              className={classnames('col-md-5 col-lg-7', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop14}
                className="project-image ppr"
                height={isMobile ? '100%' : '375px'}
                alt=""
              />
            </div>
            <div
              className={classnames('col-md-4 col-lg-3', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.findpopcentral.findpop15}
                className="project-image"
                height={isMobile ? '100%' : '375px'}
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpop16}
              className="img-fluid"
              height="100%"
              alt=""
            />
          </div>

          <div className="row">
            <div className="col-6">
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop17}
                  className="project-image"
                  height="auto"
                  alt=""
                />
              </div>
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop18}
                  className="project-image"
                  height="auto"
                  alt=""
                />
              </div>
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop21}
                  className="project-image"
                  height="auto"
                  alt=""
                />
              </div>
            </div>

            <div className="col-6">
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop19}
                  className="project-image"
                  height="auto"
                  alt=""
                />
              </div>
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop20}
                  className="project-image"
                  height="auto"
                  alt=""
                />
              </div>
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.findpopcentral.findpop22}
                  className="project-image"
                  height="auto"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.findpopcentral.findpop23}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              Animated Versions
            </h3>
          </div>

          <div
            className={classnames('mw-750 mb-10', { 'pr-0 pl-0': isMobile })}
          >
            <p className="paragraph text-center mb-0">
              We also added life to the visual by adding motion <br /> for use
              across all TV and Social Platforms.
            </p>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/xLtKK8fAHGw"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/ZldixGdR6Dk"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/sq1_1832lNY"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/NNZACCYocnU"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/CYF1C3bTVdc"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/xwftA40uT28"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/feqEvqW9V5o"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/2lMJfvqyfCI"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>

          <div className="mb-5">
            <div className="player-wrapper">
              <ReactPlayer
                url="https://youtu.be/pV_aUFC5Xns"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

FindPopcentral.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
