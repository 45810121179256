import React from 'react';
import ReactPlayer from 'react-player/youtube';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { CustomImage } from 'components';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';

export const PopOverview = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <div className="mb-4">
              <h3 className="heading heading--lg">
                Live Action + CGI Animation.
              </h3>
            </div>
            <p className="paragraph">
              These days we create campaigns for Popcentral TV, and we are doing
              what we call Total Design. We designed everything, from the
              strategy to the spaces, the furniture, sound design and of course,
              graphics. And we finessed smooth transitions from CGI scenes to
              live action.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <div className="mb-5">
              <h3 className="heading heading--lg text-center">Final Video</h3>
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=8DyCcLUhOW8"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Style Frames</h3>
          </div>
        </div>

        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.popoverview.popstyle1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popstyle2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popstyle3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.popoverview.popstyle4}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popstyle5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popstyle6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph paragraph--lg mb-0">
              These days we are creating campaigns for Popcentral TV, and we am
              doing what we call “Total Design”. We designed everything, from
              the strategy to the spaces, the furniture and sound design and of
              course, graphics. And we found ourselves doing smooth transitions
              from CGI scenes to Live action.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0 pb-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.popoverview.popgif}
              className="project-image"
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.pop1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.pop2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.popoverview.pop3}
              className="img-fluid"
              width="100%"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.pop4}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.pop5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              Breakdown WorkFlow
            </h3>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.popoverview.popbreakdown1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popbreakdown2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popbreakdown3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.popoverview.popbreakdown4}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popbreakdown5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.popoverview.popbreakdown6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              Short Breakdown Video
            </h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=bexxBPIGQco"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

PopOverview.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
