import React from 'react';
import { ReactComponent as LogoIcon } from 'assets/svg/logo-icon.svg';
import Bg from 'assets/img/footer-mobile.png';

export const Footer = () => (
  <footer className="footer">
    <img src={Bg} className="footer-bg" alt="" />

    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-7 col-lg-8">
          <div className="logo-area">
            <LogoIcon className="footer-logo-icon" />
            <p className="paragraph footer__title">What&apos;s up!</p>
          </div>
        </div>
        <div className="col-md-5 col-lg-4">
          <div>
            <a href="mailto:hello@spencerzill.com">hello@spencerzill.com</a>
          </div>

          <p className="paragraph footer__title show-sm mt-5">Connect</p>

          <div className="socials">
            <a
              target="_blank"
              href="https://www.instagram.com/spencer_zill/?hl=en"
              title="Instagram"
              rel="noreferrer"
              className="mr-4"
            >
              <span className="icon-instagram"></span>
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/company/spencerzill/"
              title="LinkedIn"
              rel="noreferrer"
              className="mr-4"
            >
              <span className="icon-linkedin"></span>
            </a>

            <a
              target="_blank"
              href="https://vimeo.com/512268401"
              title="Vimeo"
              rel="noreferrer"
              className="mr-4"
            >
              <span className="icon-vimeo"></span>
            </a>

            <a
              target="_blank"
              href="https://twitter.com/spencerzill"
              title="Twitter"
              rel="noreferrer"
              className="mr-4"
            >
              <span className="icon-twitter"></span>
            </a>

            <a
              target="_blank"
              href="https://www.behance.net/spencerzill?tracking_source=search_users_recommended%7Cspencerzill"
              title="Behance"
              rel="noreferrer"
            >
              <span className="icon-behance"></span>
            </a>
          </div>

          <div className="copyright">
            &copy; {new Date().getFullYear()} SpencerZill United Kingdom Ltd,
            All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
);
