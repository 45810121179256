import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { CustomImage } from 'components/CustomImage';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { FadeInWhenVisible } from 'components/FadeInVisible';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { CustomVideo } from 'components/CustomVideo';

export const Yellowstar = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section pb-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">The Client</h3>
          </div>
          <p className="paragraph color-off-white">
            MTN yellow star is one of the biggest and most influential music
            reality shows in Africa. Every year, thousands of visitors and
            millions of viewers are excited to experience the best of the best
            in music and singing.
          </p>

          <p className="paragraph font-size-small">
            MTN invited Spencerzill to reinvent one of their most critical (and
            Anticipated) <br /> Music reality Show — the Project fame. They
            wanted to improve the experience <br /> of obtaining, managing &amp;
            music talent.
          </p>
        </div>
      </div>

      <div className="section pb-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">The Challenge</h3>
          </div>
          <p className="paragraph">
            We received a carte Blanche, except for these simple rules; it
            should be no more than beautiful, end with the logo and have blue
            and yellow as it’s primary colours.
          </p>
        </div>
      </div>

      <div className="section pb-0">
        <div className="container-fluid mw-1440">
          <div className="player-wrapper">
            <ReactPlayer
              url="https://vimeo.com/543390727"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>

      <div className="section pb-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Research &amp; Approach </h3>
          </div>
          <p className="paragraph">
            After several weeks of research we found out that stars could SING!
            😃. Acoustic infrasound waves are trapped inside stars, causing the
            outer parts to pulsate and vibrate. Scientists have figured out how
            to convert these waves and vibrations to sounds and music notes.
            Amazing, right? After Further development we settled to a theme
            around the galaxy. Inspired by music and with an Afro/Retro
            Futuristic look dev approach, we used Live action, Visual effects,
            2D design &amp; 3D production combined together to create strong
            visuals that thrives in today’s dynamic world.
          </p>
        </div>
      </div>

      <div className="section pb-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Solution</h3>
          </div>
          <p className="paragraph">
            We also decided to come up with a concept based on celestial bodies,
            so we created a sonic ball called Orion. We added a focal point by
            creating Orion as the particle system, the idea is to carry music
            legends to the galaxy to experience music and the bloom of soulful
            singing. So blending music and science, we crafted a journey through
            the galaxies.
          </p>
          <p className="paragraph">
            We developed a new visual identity for The MTN Yello Star including
            graphics broadcast packages, call to entry video, color scheme,
            promotionals, and the design of a new logo. We supplied extensive
            brand guidelines to support the marketing team in all future brand
            executions.
          </p>
        </div>
      </div>

      <div className="section pb-0">
        <div className="container-fluid mw-1440">
          <div className="player-wrapper">
            <ReactPlayer
              url="https://vimeo.com/549300680"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container-fluid mw-1440">
          <div className="row before-after">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.before}
                width="100%"
                height={isMobile ? '120px' : '100%'}
                alt=""
              />
              <p
                className={classnames(
                  'heading color-white heading--lg text-center',
                  { paragraph: isMobile }
                )}
              >
                Before
              </p>
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.after}
                width="100%"
                height={isMobile ? '120px' : '100%'}
                alt=""
              />
              <p
                className={classnames(
                  'heading color-white heading--lg text-center',
                  { paragraph: isMobile }
                )}
              >
                After
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0 pb-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="img-fluid w-100"
                width="100%"
                height="100%"
                src={IMAGES.yellowstar.yellowstar1}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.yellowstar.yellowstar2}
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="img-fluid w-100"
                width="100%"
                height="100%"
                src={IMAGES.yellowstar.yellowstar4}
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo src="https://res.cloudinary.com/dky2q372t/video/upload/v1620077161/spencerzill/yellowstar/logo-loop.mp4" />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar6}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo type="yellowstar" src="performance.mp4" />
            </div>
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo type="yellowstar" src="lower-third.mp4" />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo type="yellowstar" src="lower-third2.mp4" />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Structural Plan</h3>
          </div>
          <p className="paragraph">
            So we carefully crafted and constructed the logo to work well with
            our visual approach, created an opening sequence, 5 in and out
            bumpers, transitions, Bugs, social media branding, a graphics system
            to display scores, statistics, and animations for the set/studio.
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.yellowstar.logo1}
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.logo2}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.yellowstar.logo3}
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.yellowstar.logo4}
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.logo5}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.logo6}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.logo7}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.logo8}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.logo9}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="mt-5">
            <FadeInWhenVisible>
              <ImgComparisonSlider>
                <img
                  slot="before"
                  width="100%"
                  src={IMAGES.yellowstar.slide2}
                />
                <img slot="after" width="100%" src={IMAGES.yellowstar.slide1} />
              </ImgComparisonSlider>
            </FadeInWhenVisible>
          </div>

          <div className="mt-5">
            <CustomImage
              hasScrollEffect
              className="project-image"
              src={IMAGES.yellowstar.yellowstar7}
              width="100%"
              height="100%"
              alt=""
            />
          </div>

          <div
            className={classnames('row no-gutters', {
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <div className="col-6">
              <CustomVideo src="take-off.mp4" type="yellowstar" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar9}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">
              Story boarding &amp; Ideation
            </h3>
          </div>
          <p className="paragraph">
            After speaking directly with the team at yello star, we sought more
            inspiration in sorting and pathfinding algorithms, procedural grids
            and spatial subdivision techniques. Our intent was to develop a
            unique visual language.
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image padding--right padding--bottom"
                src={IMAGES.yellowstar.story1}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image padding--right padding--bottom"
                src={IMAGES.yellowstar.story2}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image padding--bottom"
                src={IMAGES.yellowstar.story3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.story4}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.story5}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.story6}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.story7}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('row no-gutters', {
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.family1}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo src="family-scene.mp4" type="yellowstar" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.family3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.family4}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar11}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('row no-gutters', {
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar14}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar15}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('row no-gutters', {
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar16}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar23}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames({
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <CustomVideo src="orion-fall.mp4" type="yellowstar" />
          </div>

          <div
            className={classnames('row no-gutters', {
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar19}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar20}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar21}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('row', {
              'mt-5': !isMobile,
              'mt-3': isMobile
            })}
          >
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar22}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo src="curious-girl.mp4" type="yellowstar" />
            </div>
          </div>

          <div className="mb-5">
            <CustomImage
              hasScrollEffect
              className="project-image"
              src={IMAGES.yellowstar.yellowstar24}
              width="100%"
              height="100%"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar25}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo src="cloud.mp4" type="yellowstar" />
            </div>
          </div>

          <div
            className={classnames({
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomVideo src="mtn-orb-rotation.mp4" type="yellowstar" />
          </div>

          <div
            className={classnames({
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              className="project-image"
              src={IMAGES.yellowstar.yellowstar27}
              width="100%"
              height="100%"
              alt=""
            />
          </div>

          <div className="row no-gutters mb-5">
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar28}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar29}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="mb-0">
            <CustomImage
              hasScrollEffect
              className="project-image"
              src={IMAGES.yellowstar.yellowstar30}
              width="100%"
              height="100%"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">
              Impact at an unprecedented scale
            </h3>
          </div>

          <p className="paragraph color-off-white">
            From the core motion and interaction behaviours of Yello Star&apos;s
            visual identity, to changing the whole landscape of music reality
            shows in Africa.
          </p>

          <p className="paragraph color-off-white">
            Orion is more than just a star, it is a constellation, meaning it
            contains millions of stars grouped into galaxies. A constellation
            filled with some the brightest stars, trails and clusters - home to
            a concert of stars that never ends.
          </p>

          <p className="paragraph">
            This inspired us to create the Orion ball, down to the tiniest
            detail. If you look closely, you&apos;d see tiny balls(stars)
            connected by lines(trails), black specks (clusters of galaxies),
            bright electrifying lights(sound waves) all held together by a
            golden sphere (Orion&apos;s belt)
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-12 mb-5">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar31}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstargif3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar33}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar34}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo src="orion-dev.mp4" type="yellowstar" />
            </div>
            <div className="col-6">
              <CustomVideo src="mtn-logo.mp4" type="yellowstar" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar37}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Visual identity</h3>
          </div>

          <p className="paragraph color-off-white">
            After extensive competitor research, we identified a color space and
            developed visual language specifically designed to help Yello Star
            stand out from the rest of the market, aid visual recall and develop
            brand awareness. The bright blue envelope mark, lively palette and
            quirky patterns also evoke Yello Star&apos;s core culture and
            values;{' '}
            <span className="font-bold">
              positivity, passion &amp; diversity.
            </span>
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown1}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown2}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown4}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown5}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown6}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomVideo src="nationals.mp4" type="yellowstar" />
            </div>

            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown8}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown9}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown10}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.countdown11}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mb-5">
            <div className="mw-750">
              <div className="mb-4">
                <h3 className="heading heading--lg">Behind the renders</h3>
              </div>
              <p className="paragraph">Bringing the magic to life</p>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-md-5">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.scene3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-md-5">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.scene4}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            {!isMobile && (
              <div className="col-md-2">
                <CustomImage
                  hasScrollEffect
                  className="project-image"
                  src={IMAGES.yellowstar.scene5}
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
            )}

            <div
              className={classnames('col-12', {
                'mt-5': !isMobile,
                'mt-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.scene6}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Bringing the magic to life</h3>
          </div>

          <p className="paragraph">
            We experimented endlessly with the Yello Star particle system,
            trying to find the most interesting and easy way to convey its
            adaptiveness + flexibility. Here are some ideas we loved but didn’t
            quite make the cut.
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar38}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar40}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar44}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar46}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar41}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar42}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar56}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo src="simulation-test.mp4" type="yellowstar" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar58}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar59}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar60}
                width="100%"
                height="100%"
                alt=""
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar64}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar65}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div className="row mt-5">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar73}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.yellowstar.yellowstar74}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="player-wrapper">
            <ReactPlayer
              url="https://vimeo.com/543391385"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

Yellowstar.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
