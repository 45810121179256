import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SLANT_KEY_MAP } from 'utility/constants';
import { Portfolio } from './Portfolio';

export const Portfolios = ({ data, isTab }) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState('');

  const breakIntoSections = (portfolio) => {
    let counter = 0;
    const arr = portfolio.map((item) => {
      if (!isTab && counter === 7) {
        counter = 0;
      }

      if (isTab && counter === 8) {
        counter = 0;
      }

      const obj = {
        ...item,
        class: isTab
          ? SLANT_KEY_MAP[counter].mobile.class
          : SLANT_KEY_MAP[counter].desktop.class,
        slant_type: isTab
          ? SLANT_KEY_MAP[counter].mobile.slant_type
          : SLANT_KEY_MAP[counter].desktop.slant_type
      };
      counter++;
      return obj;
    });

    return arr;
  };

  return (
    <>
      <div className="row no-gutters portfolio-animation">
        {breakIntoSections(data).map((item, index) => (
          <Portfolio
            key={index}
            item={item}
            isTab={isTab}
            setSelectedPortfolio={setSelectedPortfolio}
            selectedPortfolio={selectedPortfolio}
          />
        ))}
      </div>
    </>
  );
};

Portfolios.propTypes = {
  data: PropTypes.array,
  isTab: PropTypes.bool
};
