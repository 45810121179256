/* eslint-disable camelcase */
// ravenbank project
const image1 =
  'https://res.cloudinary.com/https-spencerzill-com/image/upload/v1650364640/RavenBank/RavenImages/HeroImage_2x_ijntdb.png';

const RAVENBANK_IMAGES = {
  image1
};

export default RAVENBANK_IMAGES;
