import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';
import { CustomVideo } from 'components/CustomVideo';

export const Geely = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">The New Emgrand 7</h3>
          </div>
          <p className="paragraph">
            Geely’s longest running and best selling model Emgrand7 has been
            comprehensively upgraded. A classic redefined.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">The challenge </h3>
          </div>
          <p className="paragraph">
            The challenge was to present an innovative and intangible product in
            an efficient way. After warming up our neurones, brainstorming
            ideas, and sharpening our styluses, the team focused on creating the
            elements necessary to establish the Geely identity to tell a
            compelling story.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Final Video</h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://vimeo.com/535560344"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Approach</h3>
          </div>
          <p className="paragraph">
            We created a series of Key visuals to launch Geely’s Emgrand 7 on
            the Nigerian market. Our team traveled to Ibadan to shoot the
            natural environment for which the vehicle was created. We led the
            Geely crew and while the ad was being created we directed, shot
            &amp; photographed the scenes in four different locations. This was
            one of the most demanding parts of the creative journey, as we
            climbed 4950m above sea level.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely1}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely2}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely3}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely4}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.spark}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely6}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely7}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely8}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely9}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely10}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely11}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely12}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely13}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                src={IMAGES.geely.geely14}
                width="100%"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">A tailor-made realization.</h3>
          </div>
          <p className="paragraph">
            Telling a new story of an iconic brand demands dedication. Requires
            respect. The new Geely&apos;s Emgrand7 has been developed in close
            collaboration with the Mikano team and is a continuation of the
            comprehensive brand refresh introduced by Geely.
          </p>
          <p className="paragraph">
            We worked on VFX supervision of the shots to enable easy compositing
            during post production, we created the story &amp; compelling
            visuals to effortlessly support it, we created the vehicles in CGI
            and of course, we retouched the scenes.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely15}
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely16}
                alt=""
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo src="https://res.cloudinary.com/dky2q372t/video/upload/v1620077117/spencerzill/geely/start.mp4" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely17}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely18}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely19}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo src="sit.mp4" type="geely" />
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Street View</h3>
          </div>
          <p className="paragraph">
            The Emgrand 7 was designed and developed in 3D to highlight the
            reliability, power, and confidentiality of this emaculate driving
            machine. The animations and the design complement each other to
            propel the Emgrand 7 to the forefront and make it one of the
            benchmarks in its sector. From strategy to story boarding we never
            left any stone unturned.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mw-750">
            <CustomImage
              hasScrollEffect
              className="project-image"
              width="100%"
              height="100%"
              src={IMAGES.geely.geely20}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-6">
              <CustomVideo src="sketch.mp4" type="geely" />
            </div>
            <div className="col-6">
              <CustomVideo src="rear-light.mp4" type="geely" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely21}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely22}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely23}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo src="roof.mp4" type="geely" />
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mw-750">
            <CustomImage
              hasScrollEffect
              className="project-image"
              width="100%"
              height="100%"
              src={IMAGES.geely.geely24}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Head in the clouds</h3>
          </div>
          <p className="paragraph">
            The concept chosen for the new Geely documentary is a modern take on
            classic and empathic design; simplicity, sophistication, but also
            confidence and pride. The logotype is confidently displayed at the
            front of the fuselage in silver and gold. A proud beacon to the
            world, refined to a minimalistic and modern design.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row no-gutters">
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely25}
                alt=""
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomVideo src="explore.mp4" type="geely" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely26}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomVideo src="dynamics.mp4" type="geely" />
            </div>
            <div className="col-6">
              <CustomVideo src="screw-tight.mp4" type="geely" />
            </div>
            <div className="col-6">
              <CustomVideo src="wheel.mp4" type="geely" />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely28}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely29}
                alt=""
              />
            </div>
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely30}
                alt=""
              />
            </div>
            <div className="col-12">
              <CustomVideo src="end-car.mp4" type="geely" />
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">Head in the clouds</h3>
          </div>
          <p className="paragraph">
            The Emgrand 7 interiors have been updated and tailored to the Geely
            way of travel; materials, colors and lightning have been carefully
            selected and designed to make time onboard more enjoyable and to
            harmonise with the new exterior. Every line drawn and color chosen
            was made to feel like home, and like Geely to the rest of the world.
          </p>
          <p className="paragraph font-bold">An icon, redefined.</p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="mw-750">
          <div className="mb-4">
            <h3 className="heading heading--lg">
              A bespoke sales brochure was built for flexibility.
            </h3>
          </div>
          <p className="paragraph">
            Further more, we used CGI and 2D &amp; 3D Illustration to craft the
            Geely Emgrand 7 Brochure, The visual strategy was based on a bold
            and confidential visual proposal that uses the shade “80% dark”.
            Geely takes their users seriously and proves it by offering them a
            service with impeccable aesthetics and finishes which was graciously
            displayed in the 2020/2021 Geely brochure design.
          </p>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely31}
                alt=""
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely32}
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely33}
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely34}
                alt=""
              />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely35}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely36}
                alt=""
              />
            </div>
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely37}
                alt=""
              />
            </div>
            <div className="col-12">
              <CustomImage
                hasScrollEffect
                className="project-image"
                width="100%"
                height="100%"
                src={IMAGES.geely.geely38}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

Geely.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
