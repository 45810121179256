import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar, NavItem, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import Logo from 'assets/img/logo.png';

export const Navigation = ({ navHidden, isTab }) => {
  const NavLogo = (
    <Link to="/" className="navbar-brand">
      <img src={Logo} className="img-fluid" />
    </Link>
  );

  return (
    <Navbar className="nav" color="light" bg="transparent" expand="lg">
      <Container className="mw-1350">
        {isTab && NavLogo}

        {!navHidden && (
          <Navbar.Toggle aria-controls="navbar-nav">
            <span className="menu-icon"></span>
            <span className="menu-icon"></span>
            <span className="menu-icon"></span>
          </Navbar.Toggle>
        )}

        {!navHidden && (
          <Navbar.Collapse id="navbar-nav">
            <Nav>
              <NavItem>
                <NavLink className="nav-link link-hover" to="/about">
                  About
                </NavLink>
              </NavItem>

              {!isTab && NavLogo}

              <NavItem>
                <NavLink className="nav-link link-hover" to="/playground">
                  Playground
                </NavLink>
              </NavItem>

              {isTab && (
                <div className="mt-5 pt-4">
                  <NavItem>
                    <p className="font-bold font-size-large">
                      Say what&apos;s up!
                    </p>
                  </NavItem>

                  <NavItem>
                    <a
                      href="tel:+4407874345366"
                      className="nav-link link-hover"
                    >
                      +44 078 743 453 66
                    </a>
                  </NavItem>

                  <NavItem>
                    <a
                      href="mailto:hello@spencerzill.com"
                      className="nav-link link-hover"
                    >
                      hello@spencerzill.com
                    </a>
                  </NavItem>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
};

Navigation.propTypes = {
  isTab: PropTypes.bool,
  navHidden: PropTypes.bool
};
