import CENTRAL_CAST_IMAGES from './centralcast';
import CRACKLE_IMAGES from './snap';
import DJTAKEOVER_IMAGES from './djtakeover';
import FIND_POPCENTRAL_IMAGES from './findpopcentral';
import LORDSGIN_IMAGES from './lordsgin';
import ONTT_IMAGES from './ontt';
import POPOVERVIEW_IMAGES from './popoverview';
import STARDOM_IMAGES from './stardom';
import VIEWS_CHANNEL_IMAGES from './views';
import YELLOWSTAR_IMAGES from './yellowstar';
import GEELY_IMAGES from './geely';
import CLUBHOUSE_IMAGES from './clubhouse';
import RAVEN_IMAGES from './raven';

export const PROJECT_IMAGES = {
  centralcast: CENTRAL_CAST_IMAGES,
  clubhouse: CLUBHOUSE_IMAGES,
  cracklebase: CRACKLE_IMAGES,
  djtakeover: DJTAKEOVER_IMAGES,
  findpopcentral: FIND_POPCENTRAL_IMAGES,
  geely: GEELY_IMAGES,
  lordsgin: LORDSGIN_IMAGES,
  ontt: ONTT_IMAGES,
  popoverview: POPOVERVIEW_IMAGES,
  stardom: STARDOM_IMAGES,
  views: VIEWS_CHANNEL_IMAGES,
  yellowstar: YELLOWSTAR_IMAGES,
  raven: RAVEN_IMAGES
};
