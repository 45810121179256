// homepage projects
import avs from 'assets/img/home/projects/avs.jpg';
import centralcast from 'assets/img/home/projects/central_cast.png';
import centralcastgif from 'assets/img/home/projects/central-cast.gif';
import clubhouse from 'assets/img/home/projects/clubhouse.png';
import djtakeover from 'assets/img/home/projects/dj-takeover.jpg';
import geely from 'assets/img/home/projects/geely.jpg';
import hyundai from 'assets/img/home/projects/lordsgin.png';
import ontt from 'assets/img/home/projects/ontt.jpg';
import popcentral from 'assets/img/home/projects/popcentral.png';
import poprebrand from 'assets/img/home/projects/pop-rebrand.jpg';
import showreel from 'assets/img/home/projects/showreel.jpg';
import snap from 'assets/img/home/projects/snap.jpg';
import stardom from 'assets/img/home/projects/stardom.png';
import storyAfrica from 'assets/img/home/projects/story_africa.png';
import views from 'assets/img/home/projects/views.jpg';
import yellowstar from 'assets/img/home/projects/yellowstar.png';
import yellowstarmobile from 'assets/img/home/projects/yellowstar-mobile.jpg';
import raven from 'assets/img/home/projects/raven.jpeg';

// homepage upcoming
import africanGods from 'assets/img/home/upcoming/african-gods.jpeg';
import dontGroupUp from 'assets/img/home/upcoming/dont_grow_up.png';
import popBlocks from 'assets/img/home/upcoming/pop-blocks.jpeg';

export const HOME_IMAGES = {
  projects: {
    avs,
    centralcast,
    centralcastgif,
    clubhouse,
    djtakeover,
    geely,
    hyundai,
    ontt,
    popcentral,
    poprebrand,
    showreel,
    snap,
    stardom,
    storyAfrica,
    views,
    yellowstar,
    yellowstarmobile,
    raven
  },
  upcoming: {
    africanGods,
    dontGroupUp,
    popBlocks
  }
};
