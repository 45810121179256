import { ABOUT_IMAGES } from 'utility/images';

export const SOCIAL_IMAGES = [
  {
    image: ABOUT_IMAGES.about1,
    link: '',
    slant_type: 'left-right'
  },
  {
    image: ABOUT_IMAGES.about2,
    link: '',
    slant_type: 'left-right'
  },
  {
    image: ABOUT_IMAGES.about3,
    link: '',
    slant_type: 'left-right'
  },
  {
    image: ABOUT_IMAGES.about4,
    link: '',
    slant_type: 'left-right'
  },
  {
    image: ABOUT_IMAGES.about5,
    link: '',
    slant_type: 'left-right'
  },
  {
    image: ABOUT_IMAGES.about6,
    link: '',
    slant_type: 'left-right'
  }
];
