import React from 'react';
import PropTypes from 'prop-types';
import { isObjectEmpty } from 'utility';

export const ProjectTools = ({ list }) => {
  return (
    <div className="container-fluid pt mw-1440">
      <div className="row">
        <div className="col-4 mb-5">
          <div className="pt-col">
            <div>
              <p className="pt-heading">Project Type</p>
              {!isObjectEmpty(list) &&
                list.types.map((item, i) => (
                  <p className="pt-list" key={i}>
                    {item}
                  </p>
                ))}
            </div>
          </div>
        </div>
        <div className="col-4 mb-5">
          <div className="pt-col">
            <div>
              <p className="pt-heading">Role</p>
              {!isObjectEmpty(list) &&
                list.roles.map((item, j) => (
                  <p className="pt-list" key={j}>
                    {item}
                  </p>
                ))}
            </div>
          </div>
        </div>
        <div className="col-4 mb-5">
          <div className="pt-col">
            <div>
              <p className="pt-heading">Tools Used</p>
              {!isObjectEmpty(list) &&
                list.tools.map((item, k) => (
                  <p className="pt-list" key={k}>
                    {item}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectTools.propTypes = {
  list: PropTypes.object
};
