import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ReactComponent as LogoIcon } from 'assets/svg/logo-icon.svg';
import { GEELY_BASE_URL, YELLOWSTAR_BASE_URL } from 'utility/models';
import { MEDIA_LOADING_COLORS } from 'utility/constants';
import { FadeInWhenVisible } from './FadeInVisible';

export const CustomVideo = ({
  src,
  type,
  contain,
  resourceId,
  width,
  noPrefix
}) => {
  const [videoUrl, setVideoUrl] = useState(src);
  const [videoLoading, setVideoLoading] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    if (
      !(videoLoading && videoRef.current && videoRef.current.readyState < 3)
    ) {
      setVideoLoading(false);
    }
  }, [videoLoading]);

  useEffect(() => {
    switch (type) {
      case 'yellowstar':
        return setVideoUrl(`${YELLOWSTAR_BASE_URL}${src}`);

      case 'geely':
        return setVideoUrl(`${GEELY_BASE_URL}${src}`);

      default:
        return setVideoUrl(`${src}`);
    }
  }, [resourceId, src, type]);

  return (
    <>
      {videoLoading && (
        <SkeletonTheme
          color={MEDIA_LOADING_COLORS.main}
          highlightColor={MEDIA_LOADING_COLORS.highlight}
        >
          <div className="custom-image-skeleton">
            <LogoIcon className="custom-image-icon" />
            <Skeleton duration={1} height={400} width={width || '100%'} />
          </div>
        </SkeletonTheme>
      )}

      <LazyLoad once offset={100} height="100%" style={{ height: '100%' }}>
        <FadeInWhenVisible>
          <div className="custom-video">
            <video
              autoPlay
              muted
              loop
              preload="true"
              playsInline
              ref={videoRef}
              className={contain ? 'video-contain' : 'text'}
            >
              <source src={noPrefix ? src : videoUrl} type="video/mp4" />
            </video>
          </div>
        </FadeInWhenVisible>
      </LazyLoad>
    </>
  );
};

CustomVideo.propTypes = {
  contain: PropTypes.bool,
  noPrefix: PropTypes.bool,
  resourceId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  type: PropTypes.string
};

CustomVideo.defaultProps = {
  resourceId: 'v1620077715'
};
