import { HOME_IMAGES } from 'utility/images';
import CCLogo from 'assets/svg/clients/comedy-central.svg';
import DstvLogo from 'assets/svg/clients/dstv.svg';
import MtvLogo from 'assets/svg/clients/mtv.svg';
import NickelodeonLogo from 'assets/svg/clients/nickelodeon.svg';
import StarboyLogo from 'assets/svg/clients/starboy.svg';

export const CLIENTS = [
  {
    id: 1,
    image: MtvLogo,
    title: 'mtv base',
    class: 'font-size-x-large'
  },
  {
    id: 2,
    image: NickelodeonLogo,
    title: 'nickelodeon',
    class: 'font-size-large'
  },
  {
    id: 3,
    image: CCLogo,
    title: 'comedy central',
    class: 'font-size-xx-large'
  },
  {
    id: 4,
    image: StarboyLogo,
    title: 'star boy',
    class: 'font-size-x-large'
  },
  {
    id: 5,
    image: DstvLogo,
    title: 'dstv',
    class: 'font-size-medium'
  }
];

export const UPCOMING = [
  {
    id: 1,
    image: HOME_IMAGES.upcoming.dontGroupUp,
    title: 'Dont group up',
    slant_type: 'right'
  },
  {
    id: 2,
    image: HOME_IMAGES.upcoming.africanGods,
    title: 'African Gods',
    slant_type: 'left-right'
  },
  {
    id: 3,
    image: HOME_IMAGES.upcoming.popBlocks,
    title: 'Pop Blocks',
    slant_type: 'left'
  }
];

export const PORTFOLIO_CATEGORIES = [
  'All',
  'Animation',
  'Branding',
  'Motion',
  'VFX'
];
