import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PageContainer } from 'layouts/PageContainer';
import { motion } from 'framer-motion';
import {
  CentralCast,
  Clubhouse,
  CrackleBase,
  DJTakeover,
  FindPopcentral,
  Geely,
  LordsGin,
  Ontt,
  PopOverview,
  Stardom,
  Views,
  Yellowstar,
  Raven
} from 'components/projects';
import { Redirect, useParams } from 'react-router';
import { ANIMATION_DEFAULTS, PROJECTS } from 'utility/constants';
import { isArrayEmpty } from 'utility';
import { projectModel } from 'utility/models';

const Project = ({ isMobile, isTab }) => {
  const [projectInfo, setProjectInfo] = useState({ ...projectModel });

  const { name } = useParams();

  useEffect(() => {
    const result = PROJECTS.filter((item) => item.url === name);
    setProjectInfo(isArrayEmpty(result) ? { ...projectModel } : result[0]);
  }, [name]);

  const getProjectBody = (projectName) => {
    switch (projectName) {
      case 'central-cast':
        return (
          <CentralCast
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'clubhouse':
        return (
          <Clubhouse
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'crackle-base':
        return (
          <CrackleBase
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'dj-takeover':
        return (
          <DJTakeover
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'find-popcentral':
        return (
          <FindPopcentral
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'geely':
        return (
          <Geely isTab={isTab} isMobile={isMobile} projectInfo={projectInfo} />
        );
      case 'lords-gin':
        return (
          <LordsGin
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'ontt':
        return (
          <Ontt isTab={isTab} isMobile={isMobile} projectInfo={projectInfo} />
        );
      case 'pop-overview':
        return (
          <PopOverview
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'raven':
        return (
          <Raven isTab={isTab} isMobile={isMobile} projectInfo={projectInfo} />
        );
      case 'stardom':
        return (
          <Stardom
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      case 'views':
        return (
          <Views isTab={isTab} isMobile={isMobile} projectInfo={projectInfo} />
        );
      case 'yellostar':
        return (
          <Yellowstar
            isTab={isTab}
            isMobile={isMobile}
            projectInfo={projectInfo}
          />
        );
      default:
        return <Redirect to="/404" />;
    }
  };

  return (
    <PageContainer pageClass="page-body project" isTab={isTab}>
      <motion.div
        exit="out"
        initial="out"
        animate="in"
        variants={ANIMATION_DEFAULTS.pageTransition}
        transition={ANIMATION_DEFAULTS.duration}
      >
        {getProjectBody(name)}
      </motion.div>
    </PageContainer>
  );
};

Project.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool
};

export default Project;
