/* eslint-disable no-constant-condition */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MetaDecorator, ProjectTools } from 'components';
import { useHistory } from 'react-router-dom';
import { isArrayEmpty, isObjectEmpty } from 'utility';
import { PROJECTS } from 'utility/constants';

export const ProjectContainer = ({ projectInfo, children, className }) => {
  const [ids, setIds] = useState([]);
  const history = useHistory();

  const getFooterIds = useCallback(() => {
    const nextIds = [];

    if (projectInfo.id + 1 <= PROJECTS.length) {
      nextIds.push(projectInfo.id + 1);
    } else {
      nextIds.push(1);
    }

    if (projectInfo.id + 2 <= PROJECTS.length) {
      nextIds.push(projectInfo.id + 2);
    } else {
      nextIds.push(projectInfo.id + 2 - PROJECTS.length);
    }

    return nextIds;
  }, [projectInfo.id]);

  useEffect(() => {
    setIds(getFooterIds());
  }, [getFooterIds]);

  const getProjectById = (id) => {
    return PROJECTS.filter((item) => item.id === id)[0];
  };

  const onChangeProject = (link) => {
    history.push(link);
  };

  return (
    <>
      <MetaDecorator
        description={`The ${projectInfo.name.toLowerCase()} project was crafted by SpencerZill`}
        title={`${projectInfo.name} - SpencerZill`}
        image={projectInfo.header.image}
        imageAlt={`The image contains the picture of ${
          projectInfo.name ? projectInfo.name.toLowerCase() : ''
        } project`}
        keywords={projectInfo.name}
      />

      <section className="section project-header p-0">
        {!isObjectEmpty(projectInfo.header) && projectInfo.header.video && (
          <video
            autoPlay
            muted
            loop
            id="myVideo"
            preload="true"
            className="project-header__media project-header__video"
            playsInline
          >
            <source src={projectInfo.header.video} type="video/mp4" />
          </video>
        )}
        <img
          src={projectInfo.header.image}
          className="project-header__media"
          alt={projectInfo.name}
          // eslint-disable-next-line react/jsx-no-comment-textnodes
        />
        {projectInfo.url === 'clubhouse' || 'raven' ? null : (
          <div className="project-heading">
            <h3 className="heading text-center">{projectInfo.name}</h3>
          </div>
        )}
      </section>
      <section className={classnames('project-body', className)}>
        <ProjectTools list={projectInfo.list} />

        {children}
      </section>

      {/* {history.location.pathname === '/project/raven' ? (
        <p>THIS IS RAVEN!!!</p>
      ) : (
        <p>THIS IS not RAVEN!!!</p>
      )} */}
      {history.location.pathname === '/project/raven' ? null : (
        <section className="project-footer">
          <div
            className={classnames('section', {
              'pt-0': projectInfo.url !== 'clubhouse'
            })}
          >
            <div className="mw-750">
              <h3 className="heading color-white">
                {projectInfo.footer.title}
              </h3>
              <p className="paragraph">{projectInfo.footer.desc}</p>
              {projectInfo.url === 'clubhouse' && (
                <p className="paragraph mt-5 text-bb ">
                  What do you think of our ideas?
                </p>
              )}
            </div>
          </div>

          <div className="section pt-0">
            <div className="container-fluid container-mobile mw-1440">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="pt-col">
                    <div>
                      {!isArrayEmpty(projectInfo.credits) && (
                        <p className="pt-heading">Credits</p>
                      )}

                      {projectInfo.credits.map((item, k) => (
                        <p className="pt-list" key={k}>
                          {item.split('-')[0]}-
                          <span className="color-off-white">
                            {item.split('-')[1]}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section pt-0">
            <div className="container-fluid pr-0 pl-0">
              <div className="row no-gutters">
                <div className="col-6">
                  <div
                    onClick={() =>
                      onChangeProject(
                        `/project/${
                          getProjectById(ids[0]) && getProjectById(ids[0]).url
                        }`
                      )
                    }
                    className="project-footer-image"
                  >
                    <div className="project-footer-image--overlay"></div>
                    <img
                      src={
                        getProjectById(ids[0]) &&
                        getProjectById(ids[0]).header.image
                      }
                      alt=""
                    />
                    <p className="mb-0">Next</p>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    onClick={() =>
                      onChangeProject(
                        `/project/${
                          getProjectById(ids[1]) && getProjectById(ids[1]).url
                        }`
                      )
                    }
                    className="project-footer-image"
                  >
                    {getProjectById(ids[1]) &&
                      getProjectById(ids[1]).url !== 'clubhouse' && (
                        <div className="project-footer-image--overlay"></div>
                      )}
                    <img
                      src={
                        getProjectById(ids[1]) &&
                        getProjectById(ids[1]).header.image
                      }
                      alt=""
                    />
                    <p className="mb-0">Next</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

ProjectContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  projectInfo: PropTypes.object
};
