import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';

export const DJTakeover = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              One more time, we have had the pleasure to work with my friends at
              MTV to build an extraordinaire opening montage for the MTV DJ
              show, the most exquisite, unique &amp; Fun DJ Mix with DJ TTB in
              the music television space. Watch and enjoy!
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-0">
            <div className="mb-5">
              <h3 className="heading heading--lg text-center">Final Video</h3>
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/280454018"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <div className="mb-5">
              <h3 className="heading heading--lg text-center">Style Frames</h3>
            </div>
            <p className="paragraph pb-10">
              The goal was to create something simple with the perfect
              combination of unusual sound, compelling Beat and Beautiful
              musical elements with the purpose to inspire , elevate take you
              complately out of this world.{' '}
              <span className="font-bold">ENJOY THE TRIP</span>
            </p>
          </div>
        </div>

        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.djtakeover.djstyle1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.djtakeover.djstyle2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.djtakeover.djstyle3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.djtakeover.djstyle4}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.djtakeover.djstyle5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.djtakeover.djstyle6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

DJTakeover.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
