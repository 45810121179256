// stardom project
import stardomheader from 'assets/img/projects/stardom/header.png';
import stardommood1 from 'assets/img/projects/stardom/mood1.png';
import stardommood2 from 'assets/img/projects/stardom/mood2.png';
import stardommood3 from 'assets/img/projects/stardom/mood3.png';
import stardommood4 from 'assets/img/projects/stardom/mood4.png';
import stardommood5 from 'assets/img/projects/stardom/mood5.png';
import stardomslide1 from 'assets/img/projects/stardom/slide1.png';
import stardomslide2 from 'assets/img/projects/stardom/slide2.png';
import stardomlogo from 'assets/img/projects/stardom/stardom-logo.png';
import stardomtypography from 'assets/img/projects/stardom/stardom-typography.png';
import stardom1 from 'assets/img/projects/stardom/stardom1.png';
import stardom2 from 'assets/img/projects/stardom/stardom2.png';
import stardom3 from 'assets/img/projects/stardom/stardom3.png';
import stardom4 from 'assets/img/projects/stardom/stardom4.png';
import stardom5 from 'assets/img/projects/stardom/stardom5.png';
import stardom6 from 'assets/img/projects/stardom/stardom6.png';
import stardom7 from 'assets/img/projects/stardom/stardom7.png';
import stardom8 from 'assets/img/projects/stardom/stardom8.png';
import stardom9 from 'assets/img/projects/stardom/stardom9.png';
import stardom10 from 'assets/img/projects/stardom/stardom10.png';
import stardom11 from 'assets/img/projects/stardom/stardom11.jpg';
import stardom12 from 'assets/img/projects/stardom/stardom12.png';
import stardom13 from 'assets/img/projects/stardom/stardom13.png';
import stardom14 from 'assets/img/projects/stardom/stardom14.png';
import stardom15 from 'assets/img/projects/stardom/stardom15.png';
import stardomstyle1 from 'assets/img/projects/stardom/style1.png';
import stardomstyle2 from 'assets/img/projects/stardom/style2.png';
import stardomstyle3 from 'assets/img/projects/stardom/style3.png';
import stardomstyle4 from 'assets/img/projects/stardom/style4.png';
import stardomstyle5 from 'assets/img/projects/stardom/style5.png';
import stardomstyle6 from 'assets/img/projects/stardom/style6.png';
import stardomstyle7 from 'assets/img/projects/stardom/style7.png';
import stardomstyle8 from 'assets/img/projects/stardom/style8.png';
import stardomstyle9 from 'assets/img/projects/stardom/style9.png';
import stardomstyle10 from 'assets/img/projects/stardom/style10.png';
import stardomstyle11 from 'assets/img/projects/stardom/style11.png';
import stardomstyle12 from 'assets/img/projects/stardom/style12.png';
import stardomstyle13 from 'assets/img/projects/stardom/style13.png';

const STARDOM_IMAGES = {
  stardomheader,
  stardommood1,
  stardommood2,
  stardommood3,
  stardommood4,
  stardommood5,
  stardomslide1,
  stardomslide2,
  stardomlogo,
  stardomtypography,
  stardom1,
  stardom2,
  stardom3,
  stardom4,
  stardom5,
  stardom6,
  stardom7,
  stardom8,
  stardom9,
  stardom10,
  stardom11,
  stardom12,
  stardom13,
  stardom14,
  stardom15,
  stardomstyle1,
  stardomstyle2,
  stardomstyle3,
  stardomstyle4,
  stardomstyle5,
  stardomstyle6,
  stardomstyle7,
  stardomstyle8,
  stardomstyle9,
  stardomstyle10,
  stardomstyle11,
  stardomstyle12,
  stardomstyle13
};

export default STARDOM_IMAGES;
