import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CLUBHOUSE_VIDEOS as VIDEOS } from 'utility/videos';

export const CLUBHOUSE_MEDIA = [
  {
    id: 1,
    rowId: 1,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.background,
    srcLarge: '',
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 2,
    rowId: 1,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.ideagif,
    srcLarge: '',
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 3,
    rowId: 2,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse3,
    srcLarge: IMAGES.clubhouse.clubhouse3_2x,
    hasParent: true,
    parentClass: 'grid-image bg-cream',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 4,
    rowId: 2,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.approach,
    srcLarge: '',
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 5,
    rowId: 3,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse4,
    srcLarge: IMAGES.clubhouse.clubhouse4_2x,
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 6,
    rowId: 3,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse5,
    srcLarge: IMAGES.clubhouse.clubhouse5_2x,
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 6,
    rowId: 4,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse6,
    srcLarge: IMAGES.clubhouse.clubhouse6_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 7,
    rowId: 4,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse7,
    srcLarge: IMAGES.clubhouse.clubhouse7_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 7,
    rowId: 5,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse8,
    srcLarge: IMAGES.clubhouse.clubhouse8_2x,
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 8,
    rowId: 5,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.keywords,
    srcLarge: '',
    hasParent: true,
    parentClass: 'grid-image',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 9,
    rowId: 6,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse10,
    srcLarge: IMAGES.clubhouse.clubhouse10_2x,
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 10,
    rowId: 6,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse11,
    srcLarge: IMAGES.clubhouse.clubhouse11_2x,
    hasParent: true,
    parentClass: 'grid-image',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 11,
    rowId: 7,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse12,
    srcLarge: IMAGES.clubhouse.clubhouse12_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 12,
    rowId: 7,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse13,
    srcLarge: IMAGES.clubhouse.clubhouse13_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 13,
    rowId: 8,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse14,
    srcLarge: IMAGES.clubhouse.clubhouse14_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 14,
    rowId: 8,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse15,
    srcLarge: IMAGES.clubhouse.clubhouse15_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 15,
    rowId: 9,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse16,
    srcLarge: IMAGES.clubhouse.clubhouse16_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 16,
    rowId: 9,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.wireframe,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 17,
    rowId: 10,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse18,
    srcLarge: IMAGES.clubhouse.clubhouse18_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 18,
    rowId: 10,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse20,
    srcLarge: IMAGES.clubhouse.clubhouse20_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 19,
    rowId: 11,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.fourlines,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 20,
    rowId: 11,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse21,
    srcLarge: IMAGES.clubhouse.clubhouse21_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 21,
    rowId: 12,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse22,
    srcLarge: IMAGES.clubhouse.clubhouse22_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 22,
    rowId: 12,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse23,
    srcLarge: IMAGES.clubhouse.clubhouse23_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 23,
    rowId: 13,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse24,
    srcLarge: IMAGES.clubhouse.clubhouse24_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 24,
    rowId: 13,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse25,
    srcLarge: IMAGES.clubhouse.clubhouse25_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 25,
    rowId: 14,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.twodlogoanimation,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 26,
    rowId: 14,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse27,
    srcLarge: IMAGES.clubhouse.clubhouse27_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 27,
    rowId: 14,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse28,
    srcLarge: IMAGES.clubhouse.clubhouse28_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 28,
    rowId: 15,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse29,
    srcLarge: IMAGES.clubhouse.clubhouse29_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 29,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse30,
    srcLarge: IMAGES.clubhouse.clubhouse30_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 30,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse31,
    srcLarge: IMAGES.clubhouse.clubhouse31_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 31,
    rowId: 16,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.patternanimation,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 32,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse33,
    srcLarge: IMAGES.clubhouse.clubhouse33_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 33,
    rowId: 16,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.coloranimation,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 34,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse35,
    srcLarge: IMAGES.clubhouse.clubhouse35_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 35,
    rowId: 16,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.fontanimation,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 36,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse37,
    srcLarge: IMAGES.clubhouse.clubhouse37_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 36.5,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse38,
    srcLarge: IMAGES.clubhouse.clubhouse38_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 37,
    rowId: 16,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.groupicon,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 38,
    rowId: 16,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.twodthreed,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6 bg-black',
    class: '',
    props: { contain: true },
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 39,
    rowId: 16,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse41,
    srcLarge: IMAGES.clubhouse.clubhouse41_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 40,
    rowId: 17,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.threediconography,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: 'mb-5',
    mobileClass: 'mb-3'
  },
  {
    id: 41,
    rowId: 18,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.entertainment1,
    srcLarge: IMAGES.clubhouse.entertainment1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 42,
    rowId: 18,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.entertainment2,
    srcLarge: IMAGES.clubhouse.entertainment2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 43,
    rowId: 19,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.entertainment,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 44,
    rowId: 20,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.hangout1,
    srcLarge: IMAGES.clubhouse.hangout1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 45,
    rowId: 20,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.hangout2,
    srcLarge: IMAGES.clubhouse.hangout2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 46,
    rowId: 21,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.hangingout,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 47,
    rowId: 22,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.sport1,
    srcLarge: IMAGES.clubhouse.sport1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 48,
    rowId: 22,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.sport2,
    srcLarge: IMAGES.clubhouse.sport2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 49,
    rowId: 23,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.sports,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 50,
    rowId: 24,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.knowledge1,
    srcLarge: IMAGES.clubhouse.knowledge1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 51,
    rowId: 24,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.knowledge2,
    srcLarge: IMAGES.clubhouse.knowledge2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 52,
    rowId: 25,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.knowledge,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 53,
    rowId: 26,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.life1,
    srcLarge: IMAGES.clubhouse.life1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 54,
    rowId: 26,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.life2,
    srcLarge: IMAGES.clubhouse.life2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 55,
    rowId: 27,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.life,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 56,
    rowId: 28,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.art1,
    srcLarge: IMAGES.clubhouse.art1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 57,
    rowId: 28,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.art2,
    srcLarge: IMAGES.clubhouse.art2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 58,
    rowId: 29,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.art,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 59,
    rowId: 30,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.art1,
    srcLarge: IMAGES.clubhouse.hustle1_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 60,
    rowId: 30,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.hustle2,
    srcLarge: IMAGES.clubhouse.hustle2_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 61,
    rowId: 31,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.hustle,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 62,
    rowId: 32,
    type: 'react-player',
    hasScrollEffect: false,
    src: 'https://vimeo.com/557164431',
    srcLarge: '',
    hasParent: true,
    parentClass: 'player-wrapper',
    class: 'react-player',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 63,
    rowId: 33,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse43,
    srcLarge: IMAGES.clubhouse.clubhouse43_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 64,
    rowId: 34,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse44,
    srcLarge: IMAGES.clubhouse.clubhouse44_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 65,
    rowId: 34,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.visuallanguage,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 66,
    rowId: 34,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse46,
    srcLarge: IMAGES.clubhouse.clubhouse46_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 67,
    rowId: 34,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.peoplewave,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 68,
    rowId: 35,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse47,
    srcLarge: IMAGES.clubhouse.clubhouse47_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 69,
    rowId: 35,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.waveanimation,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 70,
    rowId: 35,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse49,
    srcLarge: IMAGES.clubhouse.clubhouse49_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 71,
    rowId: 35,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse50,
    srcLarge: IMAGES.clubhouse.clubhouse50_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 72,
    rowId: 36,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse96,
    srcLarge: IMAGES.clubhouse.clubhouse96_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 73,
    rowId: 37,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse51,
    srcLarge: IMAGES.clubhouse.clubhouse51_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 74,
    rowId: 37,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.patternstyle,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 75,
    rowId: 37,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse53,
    srcLarge: IMAGES.clubhouse.clubhouse53_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 76,
    rowId: 37,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse54,
    srcLarge: IMAGES.clubhouse.clubhouse54_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 77,
    rowId: 37,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.girlemoji,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 78,
    rowId: 37,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.modelpattern,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 79,
    rowId: 37,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse56,
    srcLarge: IMAGES.clubhouse.clubhouse56_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 80,
    rowId: 37,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse57,
    srcLarge: IMAGES.clubhouse.clubhouse57_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 81,
    rowId: 37,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse58,
    srcLarge: IMAGES.clubhouse.clubhouse58_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 82,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse60,
    srcLarge: IMAGES.clubhouse.clubhouse60_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 83,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse61,
    srcLarge: IMAGES.clubhouse.clubhouse61_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 84,
    rowId: 38,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.expoui,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 85,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse62,
    srcLarge: IMAGES.clubhouse.clubhouse62_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 86,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse63,
    srcLarge: IMAGES.clubhouse.clubhouse63_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 87,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse64,
    srcLarge: IMAGES.clubhouse.clubhouse64_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 88,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse65,
    srcLarge: IMAGES.clubhouse.clubhouse65_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 89,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse66,
    srcLarge: IMAGES.clubhouse.clubhouse66_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 90,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse67,
    srcLarge: IMAGES.clubhouse.clubhouse67_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 91,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse68,
    srcLarge: IMAGES.clubhouse.clubhouse68_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 92,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse69,
    srcLarge: IMAGES.clubhouse.clubhouse69_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 93,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse70,
    srcLarge: IMAGES.clubhouse.clubhouse70_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 94,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse71,
    srcLarge: IMAGES.clubhouse.clubhouse71_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 95,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse72,
    srcLarge: IMAGES.clubhouse.clubhouse72_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 96,
    rowId: 38,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.digitalprint,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 97,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse73,
    srcLarge: IMAGES.clubhouse.clubhouse73_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 98,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse74,
    srcLarge: IMAGES.clubhouse.clubhouse74_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 99,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse75,
    srcLarge: IMAGES.clubhouse.clubhouse75_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 100,
    rowId: 38,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse76,
    srcLarge: IMAGES.clubhouse.clubhouse76_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 101,
    rowId: 39,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.customappeals,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 102,
    rowId: 39,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse77,
    srcLarge: IMAGES.clubhouse.clubhouse77_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 103,
    rowId: 39,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse78,
    srcLarge: IMAGES.clubhouse.clubhouse78_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 104,
    rowId: 39,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse79,
    srcLarge: IMAGES.clubhouse.clubhouse79_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 105,
    rowId: 39,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse80,
    srcLarge: IMAGES.clubhouse.clubhouse80_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 106,
    rowId: 39,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse81,
    srcLarge: IMAGES.clubhouse.clubhouse81_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 107,
    rowId: 39,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse82,
    srcLarge: IMAGES.clubhouse.clubhouse82_2x,
    hasParent: true,
    parentClass: 'col-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 108,
    rowId: 40,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.advertising,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12 mt-5',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 109,
    rowId: 40,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse95,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12 mt-5',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 110,
    rowId: 40,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse83,
    srcLarge: IMAGES.clubhouse.clubhouse83_2x,
    hasParent: true,
    parentClass: 'col-12 mt-5',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 111,
    rowId: 40,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.renderhere,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12 mt-5',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 112,
    rowId: 40,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse84,
    srcLarge: IMAGES.clubhouse.clubhouse84_2x,
    hasParent: true,
    parentClass: 'col-12 mt-5',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 113,
    rowId: 40,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse85,
    srcLarge: IMAGES.clubhouse.clubhouse85_2x,
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 114,
    rowId: 40,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.nycnew,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12 mt-5',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 115,
    rowId: 41,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse86,
    srcLarge: IMAGES.clubhouse.clubhouse86_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 116,
    rowId: 41,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse87,
    srcLarge: IMAGES.clubhouse.clubhouse87_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 117,
    rowId: 41,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse88,
    srcLarge: IMAGES.clubhouse.clubhouse88_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 118,
    rowId: 41,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse89,
    srcLarge: IMAGES.clubhouse.clubhouse89_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 119,
    rowId: 41,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse90,
    srcLarge: IMAGES.clubhouse.clubhouse90_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 120,
    rowId: 41,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse91,
    srcLarge: IMAGES.clubhouse.clubhouse91_2x,
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 121,
    rowId: 42,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.billbadder,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: {},
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 122,
    rowId: 43,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse92,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 123,
    rowId: 43,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse93,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-md-6',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 124,
    rowId: 43,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.clubhouse94,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 125,
    rowId: 44,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.reviewsdesktop,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 126,
    rowId: 45,
    type: 'image',
    hasScrollEffect: true,
    src: IMAGES.clubhouse.reviewsmobile,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: 'project-image',
    desktopClass: '',
    mobileClass: ''
  },
  {
    id: 127,
    rowId: 46,
    type: 'video',
    hasScrollEffect: false,
    src: VIDEOS.talkless,
    srcLarge: '',
    hasParent: true,
    parentClass: 'col-12',
    class: '',
    props: { contain: true },
    desktopClass: '',
    mobileClass: ''
  }
];
