// lords gin project
import gin1 from 'assets/img/projects/lords-gin/gin1.png';
import gin2 from 'assets/img/projects/lords-gin/gin2.png';
import gin3 from 'assets/img/projects/lords-gin/gin3.png';
import ginheader from 'assets/img/projects/lords-gin/header.png';
import ginstyle1 from 'assets/img/projects/lords-gin/style1.png';
import ginstyle2 from 'assets/img/projects/lords-gin/style2.png';
import ginstyle3 from 'assets/img/projects/lords-gin/style3.png';
import ginstyle4 from 'assets/img/projects/lords-gin/style4.png';
import ginstyle5 from 'assets/img/projects/lords-gin/style5.png';
import ginstyle6 from 'assets/img/projects/lords-gin/style6.png';

const LORDSGIN_IMAGES = {
  gin1,
  gin2,
  gin3,
  ginheader,
  ginstyle1,
  ginstyle2,
  ginstyle3,
  ginstyle4,
  ginstyle5,
  ginstyle6
};

export default LORDSGIN_IMAGES;
