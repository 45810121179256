import React from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components';

export const LordsGin = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="mw-750">
          <p className="paragraph">
            In collaboration with Lord’s Gin and Hyundai, we created a full CGI
            product showcase video to promote Nigeria’s best gin. The ad
            promoted a chance to win a brand new Hyundai by buying just a bottle
            of the gin.
          </p>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Final Video</h3>

            <div className="player-wrapper">
              <ReactPlayer
                url="https://vimeo.com/310737278"
                className="react-player"
                width={VIDEO_PLAYER_SETTINGS.width}
                height={VIDEO_PLAYER_SETTINGS.height}
                config={VIDEO_PLAYER_SETTINGS.config}
                muted={true}
                controls={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.lordsgin.gin1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.lordsgin.gin2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.lordsgin.gin3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440 mb-10">
          <div className="mt-5">
            <div className="mw-750">
              <h3 className="heading heading--lg text-center">Style Frames</h3>
              <p className="paragraph pb-3">
                This is the first in a collection of “autoMobile projects” We
                have ever worked on. We love working with cars in CG and this
                was just another reason to experiment and learn something new.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.lordsgin.ginstyle1}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.lordsgin.ginstyle2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.lordsgin.ginstyle3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.lordsgin.ginstyle4}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.lordsgin.ginstyle5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.lordsgin.ginstyle6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              Short Breakdown Video
            </h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=C-Aa4lyUxSE"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

LordsGin.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
