import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { PageContainer } from 'layouts/PageContainer';
import { ANIMATION_DEFAULTS } from 'utility/constants';
import { Link } from 'react-router-dom';
import { HOME_IMAGES } from 'utility/images';

const NotFound = ({ isTab }) => {
  return (
    <PageContainer pageClass="page-body error-page" isTab={isTab}>
      <Helmet>
        <meta name="prerender-status-code" content="404"></meta>
      </Helmet>

      <motion.div
        exit="out"
        initial="out"
        animate="in"
        variants={ANIMATION_DEFAULTS.pageTransition}
        transition={ANIMATION_DEFAULTS.duration}
      >
        <img src={HOME_IMAGES.projects.showreel} className="error-img-bg" />
        <div className="error-overlay"></div>

        <section className="section">
          <div className="container">
            <h3 className="heading">404</h3>
            <p className="paragraph">Page not found</p>

            <Link to="/" className="button button--primary">
              Go home
            </Link>
          </div>
        </section>
      </motion.div>
    </PageContainer>
  );
};

NotFound.propTypes = {
  isTab: PropTypes.bool
};

export default NotFound;
