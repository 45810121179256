// dj takeover project
import djheader from 'assets/img/projects/dj-takeover/header.jpg';
import djstyle1 from 'assets/img/projects/dj-takeover/style1.png';
import djstyle2 from 'assets/img/projects/dj-takeover/style2.png';
import djstyle3 from 'assets/img/projects/dj-takeover/style3.png';
import djstyle4 from 'assets/img/projects/dj-takeover/style4.png';
import djstyle5 from 'assets/img/projects/dj-takeover/style5.png';
import djstyle6 from 'assets/img/projects/dj-takeover/style6.png';

const DJTAKEOVER_IMAGES = {
  djheader,
  djstyle1,
  djstyle2,
  djstyle3,
  djstyle4,
  djstyle5,
  djstyle6
};

export default DJTAKEOVER_IMAGES;
