import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export const MetaDecorator = ({ title, description, image, keywords }) => {
  const metaDecorator = {
    hostname: 'https://spencerzill.com/',
    twitterUsername: '@spencerzill'
  };

  return (
    <Helmet>
      {/* Primary Meta Tags  */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && (
        <meta
          name="keywords"
          content={`${keywords}, visual design, africa leading visual design, visual designer in nigeria, graphics design, graphics designer in nigeria, visual effects in nigeria, visual effects, visual effects in nigeria, 2d animation, 3d animation, motion design, motion design, motion designer nigeria`}
        />
      )}

      {/* Open Graph / Facebook */}
      {title && <meta property="og:site_name" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && (
        <meta property="og:image" content={metaDecorator.hostname + image} />
      )}
      <meta
        property="og:url"
        content={
          metaDecorator.hostname +
          window.location.pathname +
          window.location.search
        }
      />

      {/* Twitter */}
      <meta
        name="twitter:url"
        content={
          metaDecorator.hostname +
          window.location.pathname +
          window.location.search
        }
      />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && (
        <meta
          name="twitter:image:src"
          content={metaDecorator.hostname + image}
        />
      )}
      <meta name="twitter:image:alt" content="Spencerzill"></meta>
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  keywords: PropTypes.string,
  title: PropTypes.string
};
