import React from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { CustomImage } from 'components/CustomImage';

export const Views = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              {`Views is a multi-channel platform where you can #ExpressYourPassion.
            A channel for every Millennial. Currently broadcasting on StarTimes
            channel 108. Views channel came with the need to design and produce
            a set of unique channel idents. From content strategy to
            storyboarding to scene design.`}
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Initial Sketchs</h3>
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewsketch1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewsketch2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              We are very used to pop colours and playful tastes, so it was not
              much of a challenge to stay disciplined on our new daily routine.
              This is the moment, where we decided to have fun with our
              situation and try to see the bright side of it.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Mood Board</h3>
          </div>

          <div className="row no-gutters">
            <div className="col-4 pb-0">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewmood1}
                className="project-masonry h-100"
                alt=""
                height="100%"
              />
            </div>
            <div className="col-4">
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.views.viewmood2}
                  className="project-masonry project-masonry--400"
                  alt=""
                />
              </div>
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.views.viewmood3}
                  className="project-masonry project-masonry--400"
                  alt=""
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.views.viewmood4}
                  className="project-masonry project-masonry--400"
                  alt=""
                />
              </div>
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.views.viewmood5}
                  className="project-masonry project-masonry--400"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="row pt-5">
            <div
              className={classnames('col-md-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.draft1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-md-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.draft2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-md-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.draft3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-md-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.draft4}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              In this age of social media, TV stations need to be entertaining
              and visually appealing enough to captivate a younger audience.
              With this idea in mind, we had to create an exciting and colourful
              brand including a customized look and feel for each show segments.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Trailer Video</h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://youtu.be/_3ulgUkLD2U"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view6}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view7}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.view8}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              We used the Views channel iconic logo as a foundation/ base for
              all segments to be built on. The idea was to create small worlds /
              environments for each segment. One of the main challenges was to
              come up with a consistent look and feel for all the segments so we
              turned the channel&apos;s logo into cubes and houses as a
              recurring feature in the various depictions of the segments’
              personalities. Enjoy!
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Style Frames</h3>
          </div>

          <h3 className="heading color-white mb-4">VC Enterprise</h3>

          <div
            className={classnames({
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.views.viewstyle1}
              className="img-fluid w-100"
              alt=""
              height="100%"
            />
          </div>

          <div className="row no-gutters">
            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewstyle2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewstyle3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewstyle4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewstyle5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360649837"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Flicks</h3>

          <div className="row no-gutters">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewflick1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewflick2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewflick3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewflick4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewflick5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div
                className={classnames({
                  'pt-4': !isMobile,
                  'pt-2': isMobile
                })}
              >
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360650020"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Music</h3>

          <div className="row no-gutters">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewmusic1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewmusic2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewmusic3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewmusic4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewmusic5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360650151"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Food</h3>

          <div className="row no-gutters">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewfood1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewfood2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewfood3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewfood4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewfood5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360650091"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Native</h3>

          <div className="row no-gutters">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewnative1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewnative2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewnative3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewnative4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewnative5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360650221"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Style</h3>

          <div className="row no-gutters">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewvcstyle1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewvcstyle2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewvcstyle3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewvcstyle4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewvcstyle5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360650285"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Trends</h3>

          <div className="row no-gutters">
            <div className="col-12 mb-5">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewtrend1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewtrend2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewtrend3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewtrend4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewtrend5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://youtu.be/x0l9ZLkknQY"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <h3 className="heading color-white mb-4">VC Extras</h3>

          <div className="row no-gutters">
            <div
              className={classnames('col-12', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewextra1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewextra2}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewextra3}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewextra4}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-6">
              <CustomImage
                hasScrollEffect
                src={IMAGES.views.viewextra5}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-12">
              <div className="pt-4">
                <div className="player-wrapper">
                  <ReactPlayer
                    url="https://vimeo.com/360649920"
                    className="react-player"
                    width={VIDEO_PLAYER_SETTINGS.width}
                    height={VIDEO_PLAYER_SETTINGS.height}
                    config={VIDEO_PLAYER_SETTINGS.config}
                    muted={true}
                    controls={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">
              Short Breakdown Video
            </h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://youtu.be/E3hw79IMcfk"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </ProjectContainer>
  );
};

Views.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
