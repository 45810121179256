import { HOME_IMAGES, PROJECT_IMAGES } from 'utility/images';

export const PORTFOLIO = [
  // 0. Raven
  {
    id: 0,
    title: 'Raven Bank',
    links: [
      {
        name: 'quick view',
        link: 'https://player.vimeo.com/video/709021545?h=d5f68abad0'
      },
      {
        name: 'full project',
        link: '/project/raven'
      },
      {
        name: 'view on behance',
        link: 'https://www.behance.net/gallery/144665791/Raven-Bank-Brand-Positioning-Visual-Identity'
      }
    ],
    image: HOME_IMAGES.projects.raven,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/https-spencerzill-com/video/upload/v1651525407/RavenBank/RavenVideos/Mascot_with_Gun_ttxdri.mp4',
      mobile:
        'https://res.cloudinary.com/https-spencerzill-com/video/upload/v1651525407/RavenBank/RavenVideos/Mascot_with_Gun_ttxdri.mp4',
      webm: ''
    },
    category: ['animation', 'branding', 'motion', 'vfx']
  },
  // 1. showreel
  {
    id: 1,
    title: 'Showreel',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/512268401'
      }
    ],
    image: HOME_IMAGES.projects.showreel,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020827/spencerzill/home-videos/showreel.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020810/spencerzill/home-videos/showreel-mobile.mp4',
      webm: ''
    },
    category: ['animation', 'branding', 'motion', 'vfx']
  },
  // 2. clubhouse
  {
    id: 2,
    title: 'Clubhouse',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/545572378'
      },
      {
        name: 'full project',
        link: '/project/clubhouse'
      }
    ],
    image: HOME_IMAGES.projects.clubhouse,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020707/spencerzill/home-videos/clubhouse.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020695/spencerzill/home-videos/clubhouse-mobile.mp4',
      webm: ''
    },
    category: ['branding']
  },
  // 3. ONTT
  {
    id: 3,
    title: 'ONTT Rebrand',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/360600305'
      },
      {
        name: 'full project',
        link: '/project/ontt'
      }
    ],
    image: HOME_IMAGES.projects.ontt,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020796/spencerzill/home-videos/ontt.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020784/spencerzill/home-videos/ontt-mobile.mp4',
      webm: ''
    },
    category: ['branding', 'motion', 'vfx']
  },
  // 4. Views channel
  {
    id: 4,
    title: 'Views Channel Rebrand',
    links: [
      {
        name: 'quick view',
        link: 'https://youtu.be/_3ulgUkLD2U'
      },
      {
        name: 'full project',
        link: '/project/views'
      }
    ],
    image: HOME_IMAGES.projects.views,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020898/spencerzill/home-videos/views-channel.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020889/spencerzill/home-videos/views-channel-mobile.mp4',
      webm: ''
    },
    category: ['branding']
  },
  // 5. Central cast
  {
    id: 5,
    title: 'Central Cast',
    links: [
      {
        name: 'quick view',
        link: 'https://youtu.be/HC5vPT6YR8U'
      },
      {
        name: 'full project',
        link: '/project/central-cast'
      }
    ],
    image: HOME_IMAGES.projects.centralcast,
    imageGif: HOME_IMAGES.projects.centralcastgif,
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020688/spencerzill/home-videos/central-cast.mp4',
      mobile: '',
      webm: ''
    },
    category: ['branding']
  },
  // 6. Yello star
  {
    id: 6,
    title: 'Yello Star',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/360605387'
      },
      {
        name: 'full project',
        link: '/project/yellostar'
      }
    ],
    image: HOME_IMAGES.projects.yellowstar,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020911/spencerzill/home-videos/yellowstar.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020914/spencerzill/home-videos/yellow-star-mobile.mp4',
      webm: ''
    },
    category: ['vfx', 'animation']
  },
  // 7. Popcentral rebrand
  {
    id: 7,
    title: 'Popcentral Rebrand',
    links: [
      {
        name: 'coming soon',
        link: ''
      }
    ],
    image: HOME_IMAGES.projects.poprebrand,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020838/spencerzill/home-videos/pop-rebrand.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020809/spencerzill/home-videos/pop-rebrand-mobile.mp4',
      webm: ''
    },
    category: ['branding', 'animation']
  },
  // 8. Hyundai
  {
    id: 8,
    title: 'Hyundai',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/310737278'
      },
      {
        name: 'full project',
        link: '/project/lords-gin'
      }
    ],
    image: HOME_IMAGES.projects.hyundai,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020799/spencerzill/home-videos/lords-gin.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020779/spencerzill/home-videos/lords-gin-mobile.mp4',
      webm: ''
    },
    category: ['motion']
  },
  // 9. Emgrand 7
  {
    id: 9,
    title: 'Emgrand 7',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/535560344'
      },
      {
        name: 'full project',
        link: '/project/geely'
      }
    ],
    image: HOME_IMAGES.projects.geely,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1620076914/spencerzill/home-videos/geely.mp4',
      mobile: '',
      webm: ''
    },
    category: ['motion', 'animation']
  },
  // 10. Find popcentral
  {
    id: 10,
    title: 'Find Popcentral',
    links: [
      {
        name: 'quick view',
        link: 'https://youtu.be/IWcEnLIM6Ic'
      },
      {
        name: 'full project',
        link: '/project/find-popcentral'
      }
    ],
    image: PROJECT_IMAGES.findpopcentral.findpopheader,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020766/spencerzill/home-videos/find-popcentral.mp4',
      mobile: '',
      webm: ''
    },
    category: ['motion', 'animation']
  },
  // 11. Pop overview
  {
    id: 11,
    title: 'Pop Overview',
    links: [
      {
        name: 'quick view',
        link: 'https://www.youtube.com/watch?v=8DyCcLUhOW8'
      },
      {
        name: 'full project',
        link: '/project/pop-overview'
      }
    ],
    image: HOME_IMAGES.projects.popcentral,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020596/spencerzill/home-videos/pop-overview.mp4',
      mobile: '',
      webm: ''
    },
    category: ['vfx']
  },
  // 12. Africa V.S.
  {
    id: 12,
    title: 'Africa V.S.',
    links: [
      {
        name: 'coming soon',
        link: ''
      }
    ],
    image: HOME_IMAGES.projects.avs,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020656/spencerzill/home-videos/avs.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020627/spencerzill/home-videos/avs-mobile.mp4',
      webm: 'https://res.cloudinary.com/dky2q372t/video/upload/v1620338916/spencerzill/home-videos/avs2.webm'
    },
    category: ['branding', 'motion']
  },
  // 13. Story Africa
  {
    id: 13,
    title: 'Story Africa',
    links: [
      {
        name: 'coming soon',
        link: ''
      }
    ],
    image: HOME_IMAGES.projects.storyAfrica,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020885/spencerzill/home-videos/story-africa.mp4',
      mobile:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020882/spencerzill/home-videos/story-africa-mobile.mp4',
      webm: ''
    },
    category: ['branding']
  },
  // 14. Stardom
  {
    id: 14,
    title: 'Stardom',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/310733232'
      },
      {
        name: 'full project',
        link: '/project/stardom'
      }
    ],
    image: HOME_IMAGES.projects.stardom,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020871/spencerzill/home-videos/stardom.mp4',
      mobile: '',
      webm: ''
    },
    category: ['vfx']
  },
  // 15. Snap
  {
    id: 15,
    title: 'Snap',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/280441385'
      },
      {
        name: 'full project',
        link: '/project/crackle-base'
      }
    ],
    image: HOME_IMAGES.projects.snap,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1619020859/spencerzill/home-videos/snap.mp4',
      mobile: '',
      webm: ''
    },
    category: ['motion', 'animation']
  },
  // 16. DJ Takeover
  {
    id: 16,
    title: 'DJ Takeover',
    links: [
      {
        name: 'quick view',
        link: 'https://vimeo.com/280454018'
      },
      {
        name: 'full project',
        link: '/project/dj-takeover'
      }
    ],
    image: HOME_IMAGES.projects.djtakeover,
    imageGif: '',
    video: {
      desktop:
        'https://res.cloudinary.com/dky2q372t/video/upload/v1620082613/spencerzill/home-videos/dj-takeover.mp4',
      mobile: '',
      webm: ''
    },
    category: ['motion']
  }
];
