import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ProjectContainer } from 'layouts/ProjectContainer';
import { VIDEO_PLAYER_SETTINGS } from 'utility/constants';
import { CustomImage } from 'components';
import { PROJECT_IMAGES as IMAGES } from 'utility/images';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { FadeInWhenVisible } from 'components/FadeInVisible';

export const Stardom = ({ isMobile, projectInfo }) => {
  return (
    <ProjectContainer projectInfo={projectInfo}>
      <div className="section">
        <div className="container-fluid p-0">
          <div className="mw-750">
            <p className="paragraph">
              We were contacted by the amazing people over at Stardom Nigeria to
              develop a new look/call for entry video for the first season of
              the reality TV Show {`"Stardom"`}. <br /> <br />
              Stardom is aimed at launching unrefined music talents to
              contribute a new sound and a fresh take from the usual
              musicianship and showmanship.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Final Video</h3>
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              url="https://vimeo.com/310733232"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Visual Identity</h3>
          </div>

          <div className="player-wrapper mb-5">
            <ReactPlayer
              url="https://vimeo.com/360605387"
              className="react-player"
              width={VIDEO_PLAYER_SETTINGS.width}
              height={VIDEO_PLAYER_SETTINGS.height}
              config={VIDEO_PLAYER_SETTINGS.config}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>

          <div className="w-100">
            <CustomImage
              hasScrollEffect
              src={IMAGES.stardom.stardomlogo}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="w-100">
            <CustomImage
              hasScrollEffect
              src={IMAGES.stardom.stardomtypography}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div
              className={classnames('col-5', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardom1}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div className="col-7">
              <div className="row h-100">
                <div className="col-7">
                  <div
                    className={classnames('h-50', {
                      'pb-4': !isMobile,
                      'pb-2': isMobile
                    })}
                  >
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.stardom.stardom2}
                      className="project-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div
                    className={classnames('h-50', {
                      'pb-4': !isMobile,
                      'pb-2': isMobile
                    })}
                  >
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.stardom.stardom3}
                      className="project-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-5">
                  <div
                    className={classnames('h-50', {
                      'pb-4': !isMobile,
                      'pb-2': isMobile
                    })}
                  >
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.stardom.stardom4}
                      className="project-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                  <div
                    className={classnames('h-50', {
                      'pb-4': !isMobile,
                      'pb-2': isMobile
                    })}
                  >
                    <CustomImage
                      hasScrollEffect
                      src={IMAGES.stardom.stardom5}
                      className="project-image"
                      height="100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5">
            <div
              className={classnames('col-4', {
                'pb-4': !isMobile,
                'pb-2': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardom6}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>

            <div className="col-4">
              <div
                className={classnames('h-50', {
                  'pb-4': !isMobile,
                  'pb-2': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardom7}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div
                className={classnames('h-50', {
                  'pb-4': !isMobile,
                  'pb-2': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardom8}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>

            <div className="col-4">
              <div
                className={classnames('h-50', {
                  'pb-4': !isMobile,
                  'pb-2': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardom9}
                  className="project-image"
                  alt=""
                />
              </div>
              <div
                className={classnames('h-50', {
                  'pb-4': !isMobile,
                  'pb-2': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardom10}
                  className="project-image"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="w-100 pt-5">
            <CustomImage
              hasScrollEffect
              src={IMAGES.stardom.stardom11}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Mood Board</h3>
          </div>

          <div className="row no-gutters">
            <div className="col-4 pb-0">
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardommood1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div className="col-4">
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardommood2}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardommood3}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardommood4}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="mb-0">
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardommood5}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid p-0 mw-1440">
          <div className="mw-750">
            <p className="paragraph">
              The main objective for the refresh was to mature the brand, create
              scalable consistent brand visuals and applications. We defined and
              executed communications that felt relatable and inclusive, while
              embracing boldness through secondary elements that tell the story
              of musical advancement, thought collaboration, and a passion for
              the creators.
            </p>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.stardom.stardom12}
              className="img-fluid"
              alt=""
            />
          </div>

          <div className="row">
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardom13}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-6', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardom14}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>

          <div
            className={classnames('w-100', {
              'mb-5': !isMobile,
              'mb-3': isMobile
            })}
          >
            <CustomImage
              hasScrollEffect
              src={IMAGES.stardom.stardom15}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="mb-5">
            <h3 className="heading heading--lg text-center">Style Frames</h3>
          </div>

          <div className="row">
            <div
              className={classnames('col-5 col-lg-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle1}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-7 col-lg-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle2}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-7 col-lg-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle3}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-5 col-lg-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle4}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-5 col-lg-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle5}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-7 col-lg-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle6}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-7 col-lg-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle7}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-5 col-lg-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle8}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>

            <div
              className={classnames('col-5 col-lg-4', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle9}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
            <div
              className={classnames('col-7 col-lg-8', {
                'mb-5': !isMobile,
                'mb-3': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle10}
                className="project-image"
                height="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <div className="row">
            <div
              className={classnames('col-7', {
                'pb-4': !isMobile,
                'pb-2': isMobile
              })}
            >
              <CustomImage
                hasScrollEffect
                src={IMAGES.stardom.stardomstyle11}
                className="project-image"
                alt=""
                height="100%"
              />
            </div>
            <div className="col-5">
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardomstyle12}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
              <div
                className={classnames({
                  'mb-5': !isMobile,
                  'mb-3': isMobile
                })}
              >
                <CustomImage
                  hasScrollEffect
                  src={IMAGES.stardom.stardomstyle13}
                  className="project-image"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container-fluid mw-1440">
          <FadeInWhenVisible>
            <ImgComparisonSlider>
              <img
                slot="before"
                width="100%"
                src={IMAGES.stardom.stardomslide2}
              />
              <img
                slot="after"
                width="100%"
                src={IMAGES.stardom.stardomslide1}
              />
            </ImgComparisonSlider>
          </FadeInWhenVisible>
        </div>
      </div>
    </ProjectContainer>
  );
};

Stardom.propTypes = {
  isMobile: PropTypes.bool,
  isTab: PropTypes.bool,
  projectInfo: PropTypes.object
};
