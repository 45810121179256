import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as LogoSvg } from 'assets/svg/logo-icon.svg';
import { useHistory } from 'react-router-dom';

export const HomeHero = ({ isMobile }) => {
  const [selectedLink, setSelectedLink] = useState();

  const history = useHistory();

  const handleClick = (link) => {
    setSelectedLink(link);

    setTimeout(() => {
      history.push(`/${link}`);
    }, 500);
  };

  if (isMobile) {
    return (
      <div className="container mobile-hero">
        <div className="mobile-heading">
          <h3 className="heading heading--xl">
            Making <br /> impossible <br /> things exist.
          </h3>
        </div>

        <div className="mobile-nav">
          <div
            className={classnames('mobile-nav-link', {
              active: selectedLink === 'about'
            })}
            onClick={() => handleClick('about')}
          >
            <p>About</p>
            <div className="mobile-nav-icon">
              <span className="icon-long-arrow"></span>
            </div>
          </div>

          <div
            className={classnames('mobile-nav-link', {
              active: selectedLink === 'playground'
            })}
            onClick={() => handleClick('playground')}
          >
            <p>Playground</p>
            <div className="mobile-nav-icon">
              <span className="icon-long-arrow"></span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="vimeo-wrapper">
        <video autoPlay muted loop preload="true" playsInline>
          <source
            src="https://res.cloudinary.com/dky2q372t/video/upload/v1619094840/spencerzill/home-videos/homepage.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <LogoSvg className="hero__icon" />
    </>
  );
};

HomeHero.propTypes = {
  isMobile: PropTypes.bool
};
